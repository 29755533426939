<template>
<div>
    <section class="section--login">
    <div class="section__login">
      <div class="section__center">
        <div class="section__header">
          <h1 class="h2 section__title">Iniciar sesión</h1>
        </div>
        <div class="section__box">
          <form class="form form--login" v-on:submit.prevent="postLogin">
            <div class="cols">
              <div class="col-sm-12">
                <div class="form__element">
                  <div class="form__title">Email</div>
                  <div class="form__input">
                    <input id="email" type="email" v-model="form.email" required>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form__element">
                  <div class="form__title">Contraseña</div>
                  <div class="form__input">
                    <input id="password" type="password" v-model="form.password" required>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form__button">
                  <button class="btn btn--color" type="submit">Iniciar sesión</button>
                </div>
                <div class="form__notification">Porfavor ingrese correctamente sus datos<span class="fa fa-exclamation-triangle"></span></div>
                <div class="form__button">
                  <p><a @click="openmodal()">Recupera tu contraseña</a></p>
                </div>
              </div>
            </div>
          </form>
          <p>{{error}}</p>
          <p>{{token}}</p>
        </div>
      </div>
    </div>
  </section>

      <div class="modal modal--newpostulation" id="modal-recovery-pass">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--form">
            <div class="modal__loader"></div>
            <div class="modal__close" @click="closemodal()"><span class="fa fa-close"></span></div>
            <div class="modal__content">
                <div class="modal__form">
                <h3 class="modal__title">Recupera tu contraseña</h3>
                <form class="form--validation" v-on:submit.prevent="recoveryPass">

                    <div class="modal__sep modal__sep--show">
                        <div class="cols">
                          <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <div class="form__element">
                                    <div class="form__title">Correo electrónico</div>
                                    <div class="form__input">
                                        <input type="email" class="form-control" name="email_recovery" v-model="emailRecovery" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                        </div>    
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="errorRecovery">{{errorRecovery}}
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <div class="modal__notification"  style="color:green;" v-if="successRecovery">{{successRecovery}}
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <button class="btn" type="submit">Recuperar</button>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data: () => {
      return {
        form:{
            email:'',
            password:''
        },
        emailRecovery:"",
        errorRecovery:"",
        successRecovery:"",
        token:'',
        error:''
      };
  },
  mounted () {
    if (!window.localStorage.getItem('token'))  console.log("no estás logueado.")
    else this.$router.push('/dashboard')
    
  },
  methods: {
    postLogin() {
        var url = process.env.VUE_APP_URL_API+'/user/login';
        axios.post(url,{email: this.form.email,password:this.form.password})
            .then((response) => {
                if(!response.data.success) throw response.data.message;
                this.login = response.data;
                window.localStorage.setItem('token',  response.data.token_type+' '+response.data.access_token)
                window.localStorage.setItem('user',  JSON.stringify(response.data.data.user))
                //this.$router.push('/dashboard')
                //window.location.href = process.env.VUE_APP_URL_SITE+"/dashboard";
                window.location.href = "/";
            })
            .catch((error) => {
                this.error = error;
            });
        
    },
    closemodal: function(){
            var element2 = document.getElementById("modal-recovery-pass");
            element2.classList.remove("modal--in");
    },
    openmodal(){
      var element2 = document.getElementById("modal-recovery-pass");
            
      element2.classList.add("modal--in");
    },
    recoveryPass: function(){
        var url = process.env.VUE_APP_URL_API+'/user/recovery';
        axios.post(url,{email_recovery:this.emailRecovery})
          .then((response) => {
              if(!response.data.success) throw response.data.message;
              //console.log(response.data.data);
              this.successRecovery = response.data.message;

              //window.location.href = "/";
          })
          .catch((error) => {
              console.log(error);
              this.errorRecovery = error;
          });
    }
  },
  beforeUnmount() {
  }
}
</script>