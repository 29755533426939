<template>
        <div class="section__table" id="section__table--companies">
          <div class="section__table__row" v-for="(company) in companies" :key="company.id">
              <div class="section__table__element section__table__element--id">
                <div class="section__table__title">ID</div>
              <div class="section__table__value">{{company.id}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Nombre</div>
                  <div class="section__table__value">{{company.name}}</div>
              </div>
              <div class="section__table__element section__table__element--rut">
                  <div class="section__table__title">Rut</div>
                  <div class="section__table__value">{{company.rut_enterprise}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Sector Económico</div>
                  <div class="section__table__value">{{company.economicsector.name}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Región</div>
                  <div class="section__table__value">{{company.region.name}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Postulante</div>
                  <div class="section__table__value">{{company.postulant.email}}</div>
              </div>
              <div class="section__table__actions">
                <div class="section__table__title">Acciones</div>
                <div class="section__table__icons">
                    <div class="section__table__icon"  ><a :href="company.website" target="_blank"><span class="fa fa-globe"></span></a></div>
                    <div class="section__table__icon" @click="btnUpdate(company)" ><span class="fa fa-edit"></span></div>
                    <div class="section__table__icon" @click="btnDelete(company)"><span class="fa fa-trash"></span></div>
                </div>
              </div>
          </div>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapActions } from "vuex";

export default {
    name: 'GetAllCompanies',
    props: ['companies','regions','sectors'],
    mounted() {
    },
    data() {
       return {
           updated:false
       }    
    },
    created() {
    },
    methods: {
        ...mapActions(["fetchCompanies"]),
        btnUpdate:async function(company){

            let sectors = '', regions = '',sector='',region='';

            this.sectors.forEach( function(value) {
                if(value.id == company.economicsector.id)
                    sector = "<option value="+value.id+" selected>"+value.name+"</option>"
                else
                    sector = "<option value="+value.id+">"+value.name+"</option>"

                sectors = sectors + sector
            });

            this.regions.forEach( function(value) {
                if(value.id == company.region.id)
                    region = "<option value="+value.id+" selected>"+value.name+"</option>"
                else
                    region = "<option value="+value.id+">"+value.name+"</option>"

                regions = regions + region
            });

            await Swal.fire({
                title: 'Editar Empresa',
                html:
                '<div class="form-group">'+
                    '<br><div class="row">'+
                        '<div class="form__element">'+
                            '<div class="form__title">Nombre de la empresa</div>'+
                            '<div class="form__input">'+
                                '<input id="name" value="'+company.name+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Rut</div>'+
                            '<div class="form__input">'+
                                '<input id="rut" value="'+company.rut_enterprise+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Sitio web</div>'+
                            '<div class="form__input">'+
                                '<input id="website" value="'+company.website+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                            '<div class="form__element">'+
                            '<div class="form__title">Dirección</div>'+
                            '<div class="form__input">'+
                                '<input id="address" value="'+company.address+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Ciudad</div>'+
                            '<div class="form__input">'+
                                '<input id="city" value="'+company.city+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Constitución</div>'+
                            '<div class="form__input">'+
                                '<input id="constitution" value="'+company.constitution+'" type="date" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Cantidad de empleados fulltime</div>'+
                            '<div class="form__input">'+
                                '<input id="employees_fulltime" value="'+company.employees_fulltime+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Cantidad de empleados parttime</div>'+
                            '<div class="form__input">'+
                                '<input id="employees_parttime" value="'+company.employees_parttime+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Sector Económico</div>'+
                            '<div class="form__input">'+
                                '<select name="economic_sector_id" id="sector">'
                                    +sectors+
                                '</select>'+   
                            '</div>'+
                        '</div>'+
                        '<div class="form__element">'+
                            '<div class="form__title">Región</div>'+
                            '<div class="form__input">'+
                                '<select name="region_id" id="region_id" v-model="form.region_id">'
                                    +regions+
                                '</select>'+    
                            '</div>'+
                        '</div>'+
                    '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'id'  : company.id,
                            'name'  : document.getElementById('name').value,
                            'rut_enterprise'  : document.getElementById('rut').value,
                            'website'  : document.getElementById('website').value,
                            'id_economic_sector'  : document.getElementById('sector').value,
                            'id_region'  : document.getElementById('region_id').value,
                            'employees_fulltime' : document.getElementById('employees_fulltime').value,
                            'employees_parttime' : document.getElementById('employees_parttime').value,
                            'constitution': document.getElementById('constitution').value
                        }
                        var url = process.env.VUE_APP_URL_API+'/auth/enterprises/'+body.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                console.log(response)
                                if (response.data.code==200) {
                                    
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchCompanies();
                                    this.updated = true;
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            });

        },   

        btnDelete:async function(company){
            await Swal.fire({
            title: 'Borrar Categoría',
            html: '¿Seguro que deseas borrar la categoría <strong>'+company.name+' ('+company.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Categoria Eliminada', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/enterprises/'+company.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchCompanies();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        }    
    },
    beforeUnmount() {

    }
}
</script>