<template>
        <div class="section__filters">
            <div class="cols">
                <div class="col-md-4">
                <div class="form__element">
                    <div class="form__title">Buscar</div>
                    <div class="form__input">
                    <input type="text" />
                    </div>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="form__element">
                        <div class="form__title">A-Z</div>
                        <div class="form__input">
                        <select>
                            <option selected="selected" value="*">Todos</option>
                        </select>
                        </div>
                    </div>
                </div>
              <div class="col-md-4">
                  <div class="form__element">
                      <a class="btn" @click="btnNew()">Agregar Nueva</a>
                  </div>
              </div>
            </div>
        </div> 

</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import {  mapActions } from "vuex";

export default {
    name: 'GetAllExperts',
    data: function() {
        return {
            sectors: [],
            categories: []
        };
    },
    mounted() {
        this.categories = this.$store.getters.categoriesList;
        this.sectors = this.$store.getters.sectorsList;
    },
    methods: {
        ...mapActions(["fetchExperts"]),
        btnNew:async function(){
            var sectors ='', sector ='', categories ='', category ='';

            console.log(this.categories.data)

            this.sectors.data.forEach( function(value) {
                sector = "<option value="+value.id+">"+value.name+"</option>"
                sectors = sectors + sector
            });
            this.categories.data.forEach( function(value) {
                category = "<option value="+value.id+">"+value.name+"</option>"
                categories = categories + category
            });

            await Swal.fire({
            title: 'Nuevo Experto',
            html:
            '<div class="form-group">'+
                '<br><div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre del experto</div>'+
                        '<div class="form__input">'+
                            '<input id="name" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Correo</div>'+
                        '<div class="form__input">'+
                            '<input id="email" type="email" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Contraseña</div>'+
                        '<div class="form__input">'+
                            '<input id="password" type="password" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Sector</div>'+
                        '<div class="form__input">'+
                            '<select class="form-control" id="id_regional"> '+sectors+'</select>'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Categoría</div>'+
                        '<div class="form__input">'+
                            '<select class="form-control" id="id_category"> '+categories+'</select>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            preConfirm: () => {
                if(document.getElementById('name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'name'  : document.getElementById('name').value,
                        'email'  : document.getElementById('email').value,
                        'password'  : document.getElementById('password').value,
                        'id_regional'  : document.getElementById('id_regional').value,
                        'id_category'  : document.getElementById('id_category').value,
                    }
                    var url = process.env.VUE_APP_URL_API+'/auth/experts/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    //forma correcta de enviar url, body y params en axios
                    axios.post(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                                this.fetchExperts();
                            }else{
                                console.log(response.data.message) //mensaje de error}
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                        }

                },
            })
            
        },
    },
    beforeUnmount() {

    }
}
</script>
