<template>
  <div class="financials">
    <section class="section--box section--box--min increase--letters">
        <div class="section__header">
            <h4 class="section__title">Evaluaciones</h4>
        </div>
        <GetFilterEvaluations />
        <GetAllEvaluations />
    </section>
  </div>
</template>

<script>

  import GetFilterEvaluations from '@/components/evaluations/GetFilterEvaluations.vue';
  import GetAllEvaluations from '@/components/evaluations/GetAllEvaluations.vue';

  export default {
    name: 'Evaluations',
    mounted () {

    },
    components: {
      GetFilterEvaluations,
      GetAllEvaluations,
    },
  }
</script>


<style lang="css">

.increase--letters {
  font-size: 100px !important;
}

</style>
