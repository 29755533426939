<template>
<div>   
        <AddEvaluation :idPostulation="idPostulationAddEval" />
        <!-- <UpdateEvaluation :idPostulation="idPostulationAddEval" :idEvaluation="idEvaluation" :formEval="form" /> -->
        <UpdatePostulations :postulation="updPostulation" />
        <AddPostulations :currentEvaluations="currentEvaluations" :postulationName="postulationName" :idPostulation="idPostulation"/>
        <div class="section__table" id="section__table--postulations">
            <div class="section__table__row" v-for="(postulation) in postulations" :key="postulation.id">
                <div class="section__table__element section__table__element--id">
                <div class="section__table__title">ID</div>
                <div class="section__table__value">{{postulation.id}}</div>
                </div>
<div class="section__table__element section__table__element--id">
                <div class="section__table__title">ID Post.</div>
                <div class="section__table__value">{{postulation.enterprise.postulant.id}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Nombre</div>
                    <div class="section__table__value" v-if="postulation.innovation_name<35"> {{postulant.first_name}}</div>
                    <div class="section__table__value" v-else> {{postulation.innovation_name.substring(0,35)+".."}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Postulante</div>
                    <div class="section__table__value">{{postulation.enterprise.postulant.name }}</div>
                </div>
                <div v-if="user.relations.administrator != null" class="section__table__element">
                    <div class="section__table__title">Postulante Fono</div>
                    <div class="section__table__value">{{postulation.enterprise.postulant.phone }}</div>
                </div>
                <div v-if="user.relations.administrator != null" class="section__table__element">
                    <div class="section__table__title">Postulante Email</div>
                    <div class="section__table__value">{{postulation.enterprise.postulant.user.email }}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Categoría</div>
                    <div class="section__table__value" v-if="postulation.category">{{postulation.category.name}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Empresa</div>
                    <div class="section__table__value" v-if="postulation.enterprise">{{postulation.enterprise.name}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Region</div>
                    <div class="section__table__value" v-if="postulation.region">{{postulation.region.name}}</div>
                </div>
                <div class="section__table__element" v-if="user.relations.administrator != null">
                    <div class="section__table__title">Prom. Final</div>
                    <div class="section__table__value" v-if="postulation.final_prom">{{postulation.final_prom}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Estado</div>
                    <div class="section__table__value">{{postulation.active ? 'Completo' : 'Incompleto'}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Archivo Adj.</div>
                    <div class="section__table__value">{{postulation.file_attached ? 'Si' : 'No'}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Nota</div>
                    <div class="section__table__value">{{postulation.final_note_expert}}</div>
                </div>
              <div class="section__table__actions">
                <div class="section__table__title">Acciones</div>
                    <div class="section__table__icons">
                        <div v-if="(user.relations.administrator != null || user.relations.expert != null) && postulation.has_evaluation==false" class="section__table__icon" @click="addEvaluation(postulation)" ><span class="fa fa-plus"></span></div>

                        <div class="section__table__icon"  @click="btnUpdate(postulation)" >

                            <span v-if="user.relations.expert != null" class="fa fa-eye"></span>
                            <span v-else class="fa fa-edit"></span>

                        </div>

                        <div class="section__table__icon" v-if="postulation.has_evaluation==true && (user.relations.administrator != null || user.relations.expert != null)" @click="btnUpdateEval(postulation)" ><span style="color:#106CF9;" class="fa fa-pencil"></span></div>
                        <div class="section__table__icon" v-if="postulation.has_evaluation==true"  @click="seeEvaluation(postulation)" ><span class="fa fa-calculator"></span></div>
                        <div v-if="user.relations.administrator != null" class="section__table__icon" @click="btnDelete(postulation.id)"><span class="fa fa-trash"></span></div>
                    </div>
              </div>

              <div class="section__loader">
                <div class="loader">
                    <svg viewBox="25 25 50 50">
                        <circle cx="50" cy="50" r="20"></circle>
                    </svg>
                </div>
              </div>

            </div>
            
        </div>


            <div class="modal modal--newpostulation" id="modal-update-evaluation">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--form">
            <div class="modal__loader"></div>
            <div class="modal__close" @click="closemodalUpdate()"><span class="fa fa-close"></span></div>
            <div class="modal__content">
                <div class="modal__form">
                <h3 class="modal__title">Editar Evaluación
                </h3>
                <form class="form--validation form--register" v-on:submit.prevent="updateEvaluation">

                    <div class="modal__sep modal__sep--show" id="1" >
                        <div class="cols">
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Qué tan original, distintiva, disruptiva y única es la innovación postulada?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_1" min="1" max="5" pattern="[0-5]" v-model="form.note_1" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuán importante es el problema que la innovación busca resolver y cuán significativos son los beneficios que ya se han derivado y pueden derivarse de ella a futuro?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_2" min="1" max="5" pattern="[0-5]" v-model="form.note_2" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuán escalable es la innovación a nivel nacional, regional y mundial?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_3" min="1" max="5" pattern="[0-5]" v-model="form.note_3" required>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuál es su contribución desde la mirada de los Objetivos de Desarrollo Sostenible (Agenda 2030 de los 17 ODS)?¿A qué meta del ODS adhiere el proyecto?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_4" min="1" max="5" pattern="[0-5]" v-model="form.note_4" required>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-4"> 
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuál es el valor comunicacional de la innovación misma y de los beneficios para la sociedad que se derivan de ella? ¿Cuál es el potencial de difusión de la innovación y su capacidad de servir de ejemplo e inspirar a otros?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_4" min="1" max="5" pattern="[0-5]" v-model="form.note_4" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿El equipo está compuesto por personas con experiencia, conocimientos técnicos y capacidad de gestión suficientes para mantener la innovación a lo largo del tiempo y potenciarla?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_5" min="1" max="5" pattern="[0-5]" v-model="form.note_5" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal__buttons">
                            Nota final: <strong>{{finalNote}}</strong>
                        </div>
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification">Complete los campos
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <div class="modal__notification" v-if="error">{{error}}
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <div style="color:green;" class="modal__notification" v-if="success">{{success}}
                                <span class="fa fa-check"></span>
                            </div>
                            <button class="btn">Actualizar</button>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
    </div>



</div>
</template>

<script>
import axios from 'axios'
//import Swal from 'sweetalert2'
//import { mapGetters, mapActions } from "vuex";
import AddPostulations from '@/components/postulations/AddPostulations.vue';
import UpdatePostulations from '@/components/postulations/UpdatePostulations.vue';
import AddEvaluation from '@/components/postulations/AddEvaluation.vue';

import Swal from 'sweetalert2'

import { mapActions } from "vuex";

export default {
    name: 'GetAllPostulations',
    props: ['postulations'],
    mounted() {},
    components: {
      AddPostulations,
      UpdatePostulations,
      AddEvaluation
    },
    created() {
    },
    data() {
        return {
            currentEvaluations:[], 
            postulationName:'', 
            idPostulation:'',
            updPostulation:undefined,
            idPostulationAddEval:undefined,
            user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null,
            idEvaluation:'',
            form:{
                note_1:"",
                note_2:"",
                note_3:"",
                note_4:"",
                note_5:"",
                //note_6:""
            },
            finalNote:0,
            success:"",
            error:""
        }
    },
    watch: {
        currentEvaluations: {
            handler: function(val, oldVal) {
                console.log('currentEvaluations --->> nuevo valor: '+val+'      viejo valor:'+oldVal);
            },
            deep: true
        },
        form: {
            //handler: function(val, oldVal) {
            handler: function(newVal) {
                console.log(newVal);

                if(newVal.note_1 != "" && newVal.note_2 != "" && newVal.note_3 != "" && newVal.note_4 != "" && newVal.note_5 != ""){
                    let numberForm = ((newVal.note_1*process.env.VUE_APP_PORC1)+(newVal.note_2*process.env.VUE_APP_PORC2)+(newVal.note_3*process.env.VUE_APP_PORC3)+(newVal.note_4*process.env.VUE_APP_PORC4)+(newVal.note_5*process.env.VUE_APP_PORC5));
                    this.finalNote = (Math.round(numberForm*100)/100).toFixed(2);
                }else{
                    this.finalNote = 0;
                }

            },
            deep: true
        }
    },
    methods: {
        ...mapActions(["fetchPostulations"]),
        closemodalUpdate(){
            var element3 = document.getElementById("modal-update-evaluation");
            element3.classList.remove("modal--in");
            this.error = '';
            this.success = '';
        },
        addEvaluation(postulation){
            this.idPostulationAddEval = postulation.id;
            var element2 = document.getElementById("modal-add-evaluation");
            
            element2.classList.add("modal--in");
        },
        seeEvaluation:async function(postulation){

            var url = process.env.VUE_APP_URL_API+'/auth/evaluations/all?postulation_id='+postulation.id;
            var token = window.localStorage.getItem('token');
            var params =  {
                headers: {
                    Authorization: token,
                },
            }
            //forma correcta de enviar url, body y params en axios
            axios.get(url,params)
                .then((response) => {
                    console.log(response)
                    this.currentEvaluations = response.data.data.data
                    this.postulationName = postulation.innovation_name
                    this.idPostulation = postulation.id                                                                                                                   

                    var element_evaluations = document.getElementById("modal-evaluations");
                    element_evaluations.classList.add("modal--in");

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        console.log('error 401, no te encuentras autorizado.')
                        this.$router.push('/logout')
                    }
                    if (error.response.status === 404) {
                        console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                


        },
        btnUpdate(postulation){
            this.updPostulation = postulation;
            var element2 = document.getElementById("modal-update-postulation");
            
            element2.classList.add("modal--in");
        },

        btnDelete:async function(idPostulation){
            await Swal.fire({
            title: 'Borrar Postulacion',
            html: '¿Seguro que deseas borrar esta postulación ID '+idPostulation+'?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Postulación Eliminada', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/postulations/'+idPostulation+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchPostulations();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        }  ,
        updateEvaluation(){
            
            let url = process.env.VUE_APP_URL_API+'/auth/evaluations/'+this.idEvaluation+'/update';

            let token = window.localStorage.getItem('token');

            let body = {
                id_evaluation: this.idEvaluation,
                note_1:this.form.note_1,
                note_2:this.form.note_2,
                note_3:this.form.note_3,
                note_4:this.form.note_4,
                note_5:this.form.note_5,
               // note_6:this.form.note_6
            };

            let params =  {
                headers: {
                    Authorization: token
                },
            }

            axios.put(url,body,params)
                .then((response) => {
                    if(!response.data.success) throw response.data.message;

                     this.fetchPostulations();

                     this.success = response.data.message;

                })
                .catch((error) => {

                    this.error = error;

                });
        },
        btnUpdateEval(postulation){
            this.idPostulationAddEval = postulation.id;

            var url = process.env.VUE_APP_URL_API+'/auth/evaluations/all?postulation_id='+postulation.id;
            var token = window.localStorage.getItem('token');

            var params =  {
                headers: {
                    Authorization: token,
                },
            }

            axios.get(url,params)
                .then((response) => {
                    let evalDAta = response.data.data.data;

                    this.idEvaluation = evalDAta[0].id;
                    
                    this.form.note_1 = evalDAta[0].note_1;
                    this.form.note_2 = evalDAta[0].note_2;
                    this.form.note_3 = evalDAta[0].note_3;
                    this.form.note_4 = evalDAta[0].note_4;
                    this.form.note_5 = evalDAta[0].note_5;
                  //  this.form.note_6 = evalDAta[0].note_6;
                    this.finalNote  = evalDAta[0].final_note;
                    console.log(evalDAta[0]);

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        console.log('error 401, no te encuentras autorizado.')
                        this.$router.push('/logout')
                    }
                    if (error.response.status === 404) {
                        console.log('error 404, Endpoint no encontrado.')
                        }
                });
   
            var element3 = document.getElementById("modal-update-evaluation");

            element3.classList.add("modal--in");

        }
    },
    beforeUnmount() {

    },
}
</script>
