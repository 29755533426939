

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetFilterRegions',
     mounted() {
        this.sectors = this.$store.getters.sectorsList;
    },
    computed: mapGetters(["sectorsList","regionsList"]),
    created(){
        //this.fetchRegions();
    },
    methods: {
        ...mapActions(["fetchRegions"]),
        btnNew:async function(){
            var sectors = this.sectors
            var options = ""
            sectors.forEach( function(value) {
                var html = "<option value="+value.id+">"+value.name+"</option>"
                options = options + html
            });
            await Swal.fire({
            title: 'Nueva Región',
            html:
            '<div class="form-group">'+
                '<br><div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre de la Región</div>'+
                        '<div class="form__input">'+
                            '<input id="new_name"" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre del Sector</div>'+
                        '<div class="form__select">'+
                            '<select id="sector" class="form-control">'+options+'</div>'+
                        '</div>'+
                    '</div>'+
                '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            preConfirm: () => {
                if(document.getElementById('new_name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'name'  : document.getElementById('new_name').value,
                        'regional_id' : document.getElementById('sector').value
                    }
                    var url = process.env.VUE_APP_URL_API+'/auth/regions/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    //forma correcta de enviar url, body y params en axios
                    axios.post(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                                this.fetchRegions();
                            }else{
                                console.log(response.data.message) //mensaje de error
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                            }
                        });
                    }

                },
            })
            
        } 
    },
    beforeUnmount() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
