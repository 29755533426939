<template>

        <div class="section__filters">
            <div class="cols">
                <div class="col-md-12">
                    <div class="form__element">
                        <a class="btn" @click="btnNew()">Agregar Nuevo Sector</a>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllSectors',
    mounted() {},
    computed: mapGetters(["sectorsList"]),
    methods: {
        ...mapActions(["fetchSectors"]),
       btnNew:async function(){                       
            await Swal.fire({
            title: 'Nuevo Sector',
            html:
            '<div class="form-group">'+
                '<div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre del sector</div>'+
                            '<div class="form__input">'+
                                '<input id="name"" type="text" class="form-control">'+
                            '</div>'+
                        '<div class="form__notification">Sector actualizado correctamente<span class="fa fa-check"></span></div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            }).then((result) => {
                if (result.value) {
                    var url = process.env.VUE_APP_URL_API+'/auth/regionals/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    var body =  {
                        name: document.getElementById('name').value
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .post(url,body,params)
                    .then((response) => {
                        console.log(response.data)
                        this.fetchSectors();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')

                        }
                    });
                 }
                
            });
        }
    },
    beforeUnmount() {

    }
}
</script>