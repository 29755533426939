import axios from 'axios'

const state = { 
    evaluations: [],
    evals: [],
    evalsExperts: []
};

const getters = { 
    evaluationsList: state => state.evaluations,
    evalsList: state => state.evals,
    evalsExpertsList: state => state.evalsExperts
};

const actions = { 
    async fetchEvaluations({commit},num=1){
      var url = process.env.VUE_APP_URL_API+'/auth/evaluations/all?page='+num;
      var token = window.localStorage.getItem('token');

      var params =  {
          headers: {
              Authorization: token,
          }
      }

      const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );

      commit("setEvaluations", response.data.data);

    },
    async fetchEvaluationsExperts({commit},paramsGet){

        var url = process.env.VUE_APP_URL_API+'/auth/postulations/evals?'+paramsGet;
        var token = window.localStorage.getItem('token');

        var params =  {
            headers: {
                Authorization: token,
            }
        }

        const response = await axios.get(url,params)
                                .catch(
                                    error => {
                                        if (error.response.status === 401) {
                                            window.localStorage.removeItem('token');
                                            window.localStorage.removeItem('user');
                                            window.location.href = "/";
                                        }
                                        if (error.response.status === 404) {
                                            console.log('error 404, Endpoint no encontrado.')
                                        }
                                    }
                                );
        
        commit("setEvals", response.data.data.postulations);
        commit("setEvalsExperts", response.data.data.experts);
    }
};

const mutations = { 
    setEvaluations: (state, evaluations) => (
        state.evaluations = evaluations
    ),
    setEvals: (state, evals) => (
        state.evals = evals
    ),
    setEvalsExperts: (state, evalsExperts) => (
        state.evalsExperts = evalsExperts
    ),
};

export default {
    state,
    getters,
    actions,
    mutations
}