<template>
  <div class="categories">
    <section class="section--box section--box--min">
        <div class="section__header">
          <h4 class="section__title">Categorías</h4>
        </div>

        <GetFilterCategories />
        <GetAllCategories />
    </section> 
    <section class="section--graphic">
      <img src="graphics/1.png" />
    </section> 
    <section class="section--graphic">
      <img src="graphics/2.png" />
    </section> 
      
  </div>
</template>

<script>

  import GetAllCategories from '@/components/categories/GetAllCategories.vue';
  import GetFilterCategories from '@/components/categories/GetFilterCategories.vue';

  export default {
    name: 'Categories',
    mounted () {

    },
    components: {
      GetAllCategories,
      GetFilterCategories
    },
  }
</script>
