<template>
  <div class="categories">
    <section class="section--box">
        <div class="section__header">
          <h4 class="section__title">Usuarios Postulantes</h4>
        </div>

        <GetFilterPostulants />
        <GetAllPostulants :postulants="postulantsList.data"/>

        <pagination v-model="page" :records="postulantsList.total ?? 1" :per-page="20"></pagination>
    </section>    
      
  </div>
</template>

<script>

  import GetFilterPostulants from '@/components/postulants/GetFilterPostulants.vue';
  import GetAllPostulants from '@/components/postulants/GetAllPostulants.vue';
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'Postulants',
    computed: mapGetters(["postulantsList"]),
    created() {
      this.fetchPostulants();
    },
    data() {
       return {
           page: 1
       }    
    },
    mounted () {

    },
    components: {
      GetAllPostulants,
      GetFilterPostulants
    },
    methods: {
      ...mapActions(["fetchPostulants"])
    },
    watch: {
      page: {
          //handler: function(val, oldVal) {
          handler: function(val) {
              console.log(val);
            // this.fetchPostulations(['?page='+val+'&category_id='+this.categoryFilter]);
            
            this.fetchPostulants(val);
          },
          deep: true
      }
    }
    
  }
</script>
