<template>
        <div class="section__table" id="section__table--categories">
          <div class="section__table__row" v-for="(admin) in adminsList.data" :key="admin.id">
              <div class="section__table__element section__table__element--id">
              <div class="section__table__title">ID</div>
              <div class="section__table__value">{{admin.id}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Nombre</div>
                   <div class="section__table__value">{{admin.first_name}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Apellido</div>
                  <div class="section__table__value">{{admin.last_name}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Correo</div>
                  <div class="section__table__value">{{admin.user.email}}</div>
              </div>
              <div class="section__table__actions">
                  <div class="section__table__title">Acciones</div>
                  <div class="section__table__icons">
                      <div class="section__table__icon" @click="btnUpdate(admin)" ><span class="fa fa-edit"></span></div>
                      <div class="section__table__icon" @click="btnDelete(admin)"><span class="fa fa-trash"></span></div>
                  </div>
              </div>
          </div>
          <pagination v-model="page" :records="adminsList.total ?? 1" :per-page="20"></pagination>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllAdministrators',
    mounted() {},
    computed: mapGetters(["adminsList"]),
    data() {
       return {
           page: 1
       }    
    },
    created() {
    },
    methods: {
        ...mapActions(["fetchAdmins"]),
        btnUpdate:async function(admin){ 
            await Swal.fire({
                title: 'Editar Usuario Administrador',
                html:
                    '<div class="form-group">'+
                        '<div class="row">'+
                            '<div class="form__element">'+
                                '<div class="form__title">Nombre</div>'+
                                '<div class="form__input">'+
                                    '<input id="first_name"" type="text" class="form-control" value="'+admin.first_name+'">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Apellido</div>'+
                                '<div class="form__input">'+
                                    '<input id="last_name"" type="text" class="form-control" value="'+admin.last_name+'">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Correo</div>'+
                                '<div class="form__input">'+
                                    '<input id="email"" type="email" class="form-control" value="'+admin.user.email+'">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Contraseña</div>'+
                                '<div class="form__input">'+
                                    '<input id="password"" type="password" class="form-control">'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('first_name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'first_name'  : document.getElementById('first_name').value,
                            'last_name'  : document.getElementById('last_name').value,
                            'email'  : document.getElementById('email').value,
                            'password'  : document.getElementById('password').value,
                        }
                        var url = process.env.VUE_APP_URL_API+'/auth/admin/'+admin.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                console.log(response.data.data)
                                if (response.data.code==200) {
                                    
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchAdmins();
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            })
            
        },   

        btnDelete:async function(category){
            await Swal.fire({
            title: 'Borrar Categoría',
            html: '¿Seguro que deseas borrar la categoría <strong>'+category.name+' ('+category.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Categoria Eliminada', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/categories/'+category.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchAdmins()

                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        }    
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchAdmins(val)
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
