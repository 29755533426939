<template>
  <div class="categories">
    <section class="section--box">
        <div class="section__header">
          <h4 class="section__title">Empresas</h4>
        </div>

        <GetFilterCompanies @economic-sector-filter="childEconomicSectorValue" @region-filter="childRegionValue" @name-filter="childNameValue" @rut-filter="childRutValue" @name-postulant-filter="childNamePostulantValue" @rut-postulant-filter="childRutPostulantValue" />
        <GetAllCompanies :companies="companiesList.data" :regions="regionsList.data" :sectors="financialsList.data" />

        <pagination v-model="page" :records="companiesList.total ?? 1" :per-page="20"></pagination>
    </section>    
      
  </div>
</template>

<script>

  import GetFilterCompanies from '@/components/companies/GetFilterCompanies.vue';
  import GetAllCompanies from '@/components/companies/GetAllCompanies.vue';
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'Companies',
    created() {
      this.fetchCompanies();
      this.fetchRegions();
      this.fetchFinancials();
    },
    data(){
      return {
        page: 1,
        nameFilter:"",
        rutFilter:"",
        namePostulantFilter:"",
        rutPostulantFilter:"",
        economicSectorFilter:"",
        regionFilter:""
      }
    },
    mounted () {
      
    },
    methods:{
      ...mapActions(["fetchCompanies","fetchRegions","fetchFinancials"]),
      childEconomicSectorValue: function(params) {
        this.economicSectorFilter = params;
      },
      childRegionValue: function(params) {
        this.regionFilter = params;
      },
      childNameValue: function(params) {
        this.nameFilter = params;
      },
      childRutValue: function(params) {
        this.rutFilter = params;
      },
      childNamePostulantValue: function(params) {
        this.namePostulantFilter = params;
      },
      childRutPostulantValue: function(params) {
        this.rutPostulantFilter = params;
      },
      filtersAction (page) {
        let parameter = `page=${page}&name=${this.nameFilter}&rut=${this.rutFilter}&name_postulant=${this.namePostulantFilter}&rut_postulant=${this.rutPostulantFilter}&sectoreconomic_id=${this.economicSectorFilter}&region_id=${this.regionFilter}`;

        this.fetchCompanies([parameter]);
      }
    },
    computed: mapGetters(["companiesList","regionsList","financialsList"]),
    components: {
      GetAllCompanies,
      GetFilterCompanies
    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.filtersAction(val);
            },
            deep: true
        },
        economicSectorFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        },
        regionFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        },
        nameFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        },
        rutFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        },
        namePostulantFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        },
        rutPostulantFilter:{
            handler: function() {
                this.filtersAction(1);
            },
            deep: true
        }
    }
  }
</script>
