<template>
        <div class="section__filters">
          <h5 class="section__title"> Filtros </h5>
          <div class="cols">
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input">
                    <input v-on:keyup="nameFilter($event)" type="text" placeholder="Nombre"/>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input">
                    <input v-on:keyup="rutFilter($event)" type="text" placeholder="Rut"/>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input" >
                    <input v-on:keyup="namePostulantFilter($event)" type="text" placeholder="Nombre de postulante"/>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input">
                    <input v-on:keyup="rutPostulantFilter($event)" type="text" placeholder="Rut Postulante"/>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input">
                    <select @change="economicSectorFilter($event)">

                        <option selected="selected" value="">Sector económico</option>

                        <option v-for="(sector, key) in this.sectors.data" 
                                :value="sector.id"
                                :key="key">
                            {{sector.name}}
                        </option>
                    </select>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form__element">
                    <div class="form__input">
                    <select @change="regionFilter($event)">
                        <option selected="selected" value="">Región</option>
                        <option v-for="(region, key) in this.regions.data" 
                                :value="region.id"
                                :key="key">
                            {{region.name}}
                        </option>
                    </select>
                    </div>
                </div>
            </div>
          </div>
        </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetFilterCompanies.vue',
    data() {
       return {
           sectors:  [],
           regions: [],
       }    
    },
    mounted() {
        this.sectors = this.$store.getters.financialsList;
        this.regions = this.$store.getters.regionsList;
    },
    computed: mapGetters(["companiesList"]),
    created() {
        this.fetchFinancials();
        this.fetchRegions();
    },
    methods: {
        ...mapActions(["fetchCompanies",'fetchFinancials','fetchRegions']),
        economicSectorFilter (event) {
            this.$emit('economic-sector-filter', event.target.value);
        },
        regionFilter (event) {
            this.$emit('region-filter', event.target.value);
        },
        nameFilter (event) {
            this.$emit('name-filter', event.target.value);
        },
        rutFilter (event) {
            this.$emit('rut-filter', event.target.value);
        },
        namePostulantFilter (event) {
            this.$emit('name-postulant-filter', event.target.value);
        },
        rutPostulantFilter (event) {
            this.$emit('rut-postulant-filter', event.target.value);
        },
        btnNew:async function(){
            var sectors = this.sectors
            var sector = ""
            sectors.forEach( function(value) {
                var html = "<option value="+value.id+">"+value.name+"</option>"
                sector = sector + html
            });
            var regions = this.regions
            var region = ""
            regions.forEach( function(value) {
                var html = "<option value="+value.id+">"+value.name+"</option>"
                region = region + html
            });
            var postulants = this.postulants
            var postulant = ""
            postulants.forEach( function(value) {
                var html = "<option value="+value.id+">"+value.rut+' - '+value.first_name+' '+value.father_last_name+' '+value.mother_last_name+"</option>"
                postulant = postulant + html
            });
            await Swal.fire({
            title: 'Nueva Empresa',
            html:
            '<div class="form-group">'+
                '<div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre de la Empresa</div>'+
                        '<div class="form__input">'+
                            '<input id="name" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Rut</div>'+
                        '<div class="form__input">'+
                            '<input id="rut_enterprise" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Sitio web</div>'+
                        '<div class="form__input">'+
                            '<input id="website" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Dirección</div>'+
                        '<div class="form__input">'+
                            '<input id="address" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Ciudad</div>'+
                        '<div class="form__input">'+
                            '<input id="city" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Constitución</div>'+
                        '<div class="form__input">'+
                            '<input id="constitution" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Cantidad de empleados fulltime</div>'+
                        '<div class="form__input">'+
                            '<input id="employees_fulltime" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Cantidad de empleados parttime</div>'+
                        '<div class="form__input">'+
                            '<input id="employees_parttime" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Sector Económico</div>'+
                        '<div class="form__input">'+
                            '<select id="economic_sector_id" class="form-control">'+sector+'</select>'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Región</div>'+
                        '<div class="form__input">'+
                            '<select id="region_id" class="form-control">'+region+'</select>'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Postulante</div>'+
                        '<div class="form__input">'+
                            '<select id="postulant_id" class="form-control">'+postulant+'</select>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            preConfirm: () => {
                if(document.getElementById('new_name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'name'  : document.getElementById('new_name').value
                    }

                    var url = process.env.VUE_APP_URL_API+'/auth/enterprises/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios.post(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                                this.fetchCategories();
                            }else{
                                console.log(response.data.message) //mensaje de error}
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                        }

                },
            })
            
        },    
    },
    beforeUnmount() {

    }
}
</script>