import axios from 'axios'

const state = { 
    sectors: []
};

const getters = { 
    sectorsList: state => state.sectors
};

const actions = { 
    async fetchSectors({commit},num=1){
        var url = process.env.VUE_APP_URL_API+'/auth/regionals/all?page='+num;
        var token = window.localStorage.getItem('token');

        var params =  {
            headers: {
                Authorization: token,
            }
        }

        const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );

        commit("setSectors", response.data.data);
    }
};

const mutations = { 
    setSectors: (state, sectors) => (
        state.sectors = sectors
    )
};

export default {
    state,
    getters,
    actions,
    mutations
}