import axios from 'axios'

const state = { 
    financials: []
};

const getters = { 
    financialsList: state => state.financials
};

const actions = { 
    async fetchFinancials({commit},num=1){
        var url = process.env.VUE_APP_URL_API+'/auth/economicsectors/all?page='+num;
        var token = window.localStorage.getItem('token');

        var params =  {
            headers: {
                Authorization: token,
            }
        }

        const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );
        commit("setFinancials", response.data.data);
    }
};

const mutations = { 
    setFinancials: (state, financials) => (
        state.financials = financials
    )
};

export default {
    state,
    getters,
    actions,
    mutations
}