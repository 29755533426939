    <template>
        <div class="section__table" id="section__table--categories">
          <div class="section__table__row" v-for="(postulant) in postulants" :key="postulant.id">
              <div class="section__table__element section__table__element--id">
              <div class="section__table__title">ID</div>
              <div class="section__table__value">{{postulant.id ?? ''}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Nombre</div>
                   <div class="section__table__value">{{postulant.first_name ?? ''}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Apellidos</div>
                  <div class="section__table__value">{{postulant.father_last_name ?? ''}} {{ postulant.mother_last_name ?? ''}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Correo</div>
                  <div class="section__table__value">{{postulant.email ?? ''}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Rut</div>
                  <div class="section__table__value">{{postulant.rut ?? ''}}</div>
              </div>
              <div class="section__table__element">
                  <div class="section__table__title">Empresa</div>
                  <div class="section__table__value">{{postulant.enterprise ?? ''}}</div>
              </div>
              <div class="section__table__actions">
              <div class="section__table__title">Acciones</div>
              <div class="section__table__icons">
                  <div class="section__table__icon" @click="btnUpdate(postulant)" ><span class="fa fa-edit"></span></div>
                  <div class="section__table__icon" @click="btnDelete(postulant)"><span class="fa fa-trash"></span></div>
              </div>
              </div>
          </div>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    name: 'GetAllPostulants.vue',
    props: ['postulants'],
    mounted() {
    },
    created() {
    },
    data() {
       return {
           page: 1,
       }    
    },
    methods: {
        returnEmptyIfNull(val){
            if(val == null) return '';
            else return val;
        },
        btnUpdate:async function(postulant){
            await Swal.fire({
                title: 'Editar Usuario Postulante',
                html:
            '<div class="form-group">'+
                '<div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre</div>'+
                        '<div class="form__input">'+
                            '<input id="first_name" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.first_name)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Apellido paterno</div>'+
                        '<div class="form__input">'+
                            '<input id="father_last_name" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.father_last_name)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Apellido materno</div>'+
                        '<div class="form__input">'+
                            '<input id="mother_last_name" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.mother_last_name)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Correo</div>'+
                        '<div class="form__input">'+
                            '<input id="email" type="email" class="form-control" value="'+this.returnEmptyIfNull(postulant.email)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Rut</div>'+
                        '<div class="form__input">'+
                            '<input id="rut" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.rut)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Género</div>'+
                        '<div class="form__input">'+
                            '<input type="radio" id="gender" name="gender" value="female"> Femenino'+
                            '<input type="radio" id="male" name="gender" value="male"> Masculino'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Teléfono</div>'+
                        '<div class="form__input">'+
                            '<input id="phone" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.phone)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Fecha de Nacimiento</div>'+
                        '<div class="form__input">'+
                            '<input id="birth_day" type="date" class="form-control" value="'+this.returnEmptyIfNull(postulant.birth_day)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Empresa donde trabajas</div>'+
                        '<div class="form__input">'+
                            '<input id="enterprise" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.enterprise)+'">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Cargo</div>'+
                        '<div class="form__input">'+
                            '<input id="job_title" type="text" class="form-control" value="'+this.returnEmptyIfNull(postulant.job_title)+'">'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('first_name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'first_name'  : document.getElementById('first_name').value,
                            'father_last_name'  : document.getElementById('father_last_name').value,
                            'mother_last_name'  : document.getElementById('mother_last_name').value,
                            'email'  : document.getElementById('email').value,
                            'rut'  : document.getElementById('rut').value,
                            'gender'  : 'male',
                            'phone'  : document.getElementById('phone').value,
                            'birth_day'  : document.getElementById('birth_day').value,
                            'enterprise'  : document.getElementById('enterprise').value,
                            'job_title'  : document.getElementById('job_title').value,
                        }


                        var url = process.env.VUE_APP_URL_API+'/auth/postulants/'+postulant.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                console.log(response.data.data)
                                if (response.data.code==200) {
                                    
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchPostulants()
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            })
            
        },   

        btnDelete:async function(postulant){
            await Swal.fire({
            title: 'Borrar Postulante y sus postulaciones',
            html: '¿Seguro que deseas borrar el postulante? <strong>'+postulant.first_name+' '+postulant.father_last_name+' '+postulant.mother_last_name+' ('+postulant.rut+')</strong>? <br>'+
            '<small>Al borrar un postulante, estas borrando el usuario, la empresa y sus postulaciones.</smal>', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Postulante Eliminado', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/postulants/'+postulant.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchPostulants()
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        }    
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchPostulants(val)
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
