<template>
    <div>
    <div class="modal modal--newpostulation" id="modal-add-evaluation">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--form">
            <div class="modal__loader"></div>
            <div class="modal__close" @click="closemodal()"><span class="fa fa-close"></span></div>
            <div class="modal__content">
                <div class="modal__form">
                <h3 class="modal__title">Evaluación
                </h3>
                <form v-if="idPostulation != undefined" class="form--validation form--register" v-on:submit.prevent="addEvaluation">

                    <div class="modal__sep modal__sep--show" id="1" >
                        <div class="cols">
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Qué tan original, distintiva, disruptiva y única es la innovación postulada?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_1" min="1" max="5" pattern="[0-5]" v-model="form.note_1" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuán importante es el problema que la innovación busca resolver y cuán significativos son los beneficios que ya se han derivado y pueden derivarse de ella a futuro?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_2" min="1" max="5" pattern="[0-5]" v-model="form.note_2" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuán escalable es la innovación a nivel nacional, regional y mundial?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_3" min="1" max="5" pattern="[0-5]" v-model="form.note_3" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿Cuál es el valor comunicacional de la innovación misma y de los beneficios para la sociedad que se derivan de ella? ¿Cuál es el potencial de difusión de la innovación y su capacidad de servir de ejemplo e inspirar a otros?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_4" min="1" max="5" pattern="[0-5]" v-model="form.note_4" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿El equipo está compuesto por personas con experiencia, conocimientos técnicos y capacidad de gestión suficientes para mantener la innovación a lo largo del tiempo y potenciarla?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_5" min="1" max="5" pattern="[0-5]" v-model="form.note_5" required>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="col-md-4">
                                <div class="form__element form__element--box">
                                    <div class="form__title">¿El equipo está compuesto por personas con experiencia, conocimientos técnicos y capacidad de gestión suficientes para mantener la innovación a lo largo del tiempo y potenciarla?</div>
                                    <div class="form__input">
                                        <input type="number" class="form-control" name="note_6" min="1" max="5" pattern="[0-5]" v-model="form.note_6" required>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="modal__buttons">
                            Nota final: <strong>{{finalNote}}</strong>
                        </div>
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification">Complete los campos
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <div class="modal__notification" v-if="error">{{error}}
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <button class="btn">Guardar</button>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from "vuex";

export default {
    name: 'AddEvaluation',
    props: ['idPostulation'],
    data(){
        return {
            form:{
                note_1:"",
                note_2:"",
                note_3:"",
                note_4:"",
                note_5:"",
              //  note_6:""
            },
            notification: false,
            finalNote:0,
            error: ''
        }
    },
    methods: {
        ...mapActions(["fetchPostulations"]),
        closemodal: function(){
            var element2 = document.getElementById("modal-add-evaluation");
            element2.classList.remove("modal--in");
            this.error = '';
        },
        addEvaluation(){

            let url = process.env.VUE_APP_URL_API+'/auth/evaluations/create';

            let token = window.localStorage.getItem('token');

            let body = {
                id_postulation: this.idPostulation,
                note_1:this.form.note_1,
                note_2:this.form.note_2,
                note_3:this.form.note_3,
                note_4:this.form.note_4,
                note_5:this.form.note_5,
                //note_6:this.form.note_6
            };

            let params =  {
                headers: {
                    Authorization: token
                },
            }

            axios.post(url,body,params)
                .then((response) => {
                    if(!response.data.success) throw response.data.message;
                    //this.login = response.data;
                   // window.localStorage.setItem('token',  response.data.token_type+' '+response.data.access_token)
                   //window.localStorage.setItem('user',  JSON.stringify(response.data.data.user))
                     this.fetchPostulations();
                     this.closemodal();
                     this.form.note_1 = "";
                     this.form.note_2 = "";
                     this.form.note_3 = "";
                     this.form.note_4 = "";
                     this.form.note_5 = "";
                     //this.form.note_6 = "";
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        window.localStorage.removeItem('token');
                        window.localStorage.removeItem('user');
                        window.location.href = "/";
                    }
                    if (error.response.status === 404) {
                        console.log('error 404, Endpoint no encontrado.')
                    }
                });
        }
    },
    watch: {
        form: {
            //handler: function(val, oldVal) {
            handler: function(newVal) {
                console.log(process.env.VUE_APP_PORC1);
                if(newVal.note_1 != "" && newVal.note_2 != "" && newVal.note_3 != "" && newVal.note_4 != "" && newVal.note_5 != ""){

                    let numberForm = ((newVal.note_1*process.env.VUE_APP_PORC1)+(newVal.note_2*process.env.VUE_APP_PORC2)+(newVal.note_3*process.env.VUE_APP_PORC3)+(newVal.note_4*process.env.VUE_APP_PORC4)+(newVal.note_5*process.env.VUE_APP_PORC5));
                    this.finalNote = (Math.round(numberForm*100)/100).toFixed(2);
                    //this.finalNote = numberForm;

                }else{
                    this.finalNote = 0;
                }
            },
            deep: true
        },
    }
}
</script>

<style>

</style>
