import { createStore } from 'vuex'
import CategoriesModule from './modules/category-module'
import SectorsModule from './modules/sector-module'
import ExpertsModule from './modules/expert-module'
import FinancialsModule from './modules/financial-module'
import RegionsModule from './modules/region-module'
import PostulationsModule from './modules/postulation-module'
import AdminsModule from './modules/administrator-module'
import PostulantsModule from './modules/postulant-module'
import CompaniesModule from './modules/company-module'
import createPersistedState from "vuex-persistedstate";
import EvaluationsModule from './modules/evaluation-module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CategoriesModule,
    SectorsModule,
    ExpertsModule,
    FinancialsModule,
    RegionsModule,
    PostulationsModule,
    AdminsModule,
    CompaniesModule,
    PostulantsModule,
    EvaluationsModule
  },
  plugins: [createPersistedState()],
})
