import axios from 'axios'

const state = { 
    companies: []
};

const getters = { 
    companiesList: state => state.companies
};

const actions = { 
    async fetchCompanies({commit},args){
        if (typeof args == 'undefined') {
            args = '?page=1'
        }

      var url = process.env.VUE_APP_URL_API+'/auth/enterprises/all?'+args;
      var token = window.localStorage.getItem('token');

      var params =  {
          headers: {
              Authorization: token,
          }
      }

      const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );
      commit("setCompanies", response.data.data);

    },
};

const mutations = { 
    setCompanies: (state, companies) => (
        state.companies = companies
    ),
};

export default {
    state,
    getters,
    actions,
    mutations
}