<template>
    
    <div>


        <div class="section__add"><a class="btn" @click="exportToXls()">Exportar a Excel</a></div>

    </div>


</template>

<script>
import axios from 'axios'
/*
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex"; */

export default {
    name: 'ExportToXls',
    props: ['categoryFilter','regionFilter'],
    mounted() {},
    computed: {},
    data(){
        return {

        }
    },
    components: {
      
    },
    created() {
    },
    methods: {
        exportToXls(){
            let a = document.createElement("a");
            //RENDERIZAR XLS MEDIANTE AXIOS
            document.body.appendChild(a);
            a.style = "display: none";

            let args = `category_id=${this.categoryFilter}&region_id=${this.regionFilter}`;

            let url = process.env.VUE_APP_URL_API+'/auth/postulations/evals/xls?'+args;
            let token = window.localStorage.getItem('token');

            let params =  {
                headers: {
                    Authorization: token,
                },
                responseType: 'blob'
            }

            
            axios.get(url,params)
                 .then((response) => {
                       // if(!response.data.success) throw response.data.message;

                        var file = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});

                        var fileURL = URL.createObjectURL(file);

                        // Open new windows and show XLS
                        //window.open(fileURL);
                        //this.urlExportPdf = fileURL;

                        a.href = fileURL;
                        a.download = 'evaluaciones.xlsx';
                        a.click();
                 })
                 .catch(
                        error => {
                            if (error.response.status === 401) {
                                window.localStorage.removeItem('token');
                                window.localStorage.removeItem('user');
                                window.location.href = "/";
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                            }
                        }
                 );
        }

    },
    beforeUnmount() {

    },
}
</script>