<template>
    <div class="modal modal--evaluations" id="modal-evaluations">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--evaluations">
                <div class="modal__close" @click="closemodalevaluations()"><span class="fa fa-close"></span></div>
                <h4 class="h4">Postulación: {{postulationName}} (id: {{idPostulation}})</h4>
                <div class="modal__table__row" v-for="(currentEvaluation) in currentEvaluations" :key="currentEvaluation.id">
                    <div class="modal__table__element modal__table__element--name">
                        <div class="modal__table__title">Nombre Experto</div>
                        <div class="modal__table__value">{{currentEvaluation.expert.name}}</div>
                    </div>
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 1</div>
                        <div class="modal__table__value">{{currentEvaluation.note_1}}</div>
                    </div>               
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 2</div>
                        <div class="modal__table__value">{{currentEvaluation.note_2}}</div>
                    </div>               
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 3</div>
                        <div class="modal__table__value">{{currentEvaluation.note_3}}</div>
                    </div>               
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 4</div>
                        <div class="modal__table__value">{{currentEvaluation.note_4}}</div>
                    </div>               
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 5</div>
                        <div class="modal__table__value">{{currentEvaluation.note_5}}</div>
                    </div>               
                    <div class="modal__table__element">
                        <div class="modal__table__title">Nota 6</div>
                        <div class="modal__table__value">{{currentEvaluation.note_6}}</div>
                    </div>               
                    <div class="modal__table__element modal__table__element--final">
                        <div class="modal__table__title">Nota final</div>
                        <div class="modal__table__value">{{currentEvaluation.final_note}}</div>
                    </div> 
                </div>
            </div> 
        </div>                           
    </div>    
    <div class="modal modal--newpostulation" id="modal-postulation">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--form">
            <div class="modal__loader"></div>
            <div class="modal__close" @click="closemodal()"><span class="fa fa-close"></span></div>
            <div class="modal__content">
                <div class="modal__form">
                <h3 class="modal__title">Estás creando una nueva postulación</h3>
                <form class="form--validation form--register" v-on:submit.prevent="newPostulant">

                    <div class="modal__sep modal__sep--show" id="1" v-if="currentStep == 1">
                        <div class="cols">
                            <div class="col-md-4">
                                <div class="form__element">
                                    <div class="form__title">Empresa</div>
                                    <div class="form__input">
                                        <select name="company_id" id="company_id" v-model="form.company_id">
                                            <option v-for="(company, key) in this.companies.data" 
                                                    :value="company.id"
                                                    :key="key">
                                            {{company.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element">
                                    <div class="form__title">Región en la que opera la empresa</div>
                                    <div class="form__input">
                                        <select name="region_id" id="region_id" v-model="form.region_id">
                                            <option v-for="(region, key) in this.regions.data" 
                                                    :value="region.id"
                                                    :key="key">
                                            {{region.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element">
                                    <div class="form__title">Categoría</div>
                                    <div class="form__input">
                                        <select name="category_id" id="category_id" v-model="form.category_id">
                                            <option v-for="(category, key) in this.categories.data" 
                                                    :value="category.id"
                                                    :key="key">
                                            {{category.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification == true">Complete los campos
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <a class="btn" @click.prevent="goToStep(2)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="2" v-if="currentStep == 2"> 
                        <div class="cols">
                            <div class="col-md-6">
                                <div class="form__element">
                                    <div class="form__title">Nombre Innovación postulada (nombre corto o “comercial”)</div>
                                    <div class="form__input">
                                        <input id="innovation_name" type="text" class="form-control" name="innovation_name" v-model="form.innovation_name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Región (o país) en la que se implementa la innovación</div>
                                    <div class="form__input">
                                        <input id="other_place" type="text" class="form-control" name="other_place" v-model="form.other_place">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Nombre y cargo de quien lidera la innovación</div>
                                    <div class="form__input">
                                        <input id="leader" type="text" class="form-control" name="leader" v-model="form.leader">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form__element">
                                    <div class="form__title">Sexo</div>
                                    <div class="form__input">
                                        <select class="form-control" id="leader_gender" name="leader_gender" v-model="form.leader_gender">
                                            '<option value="Hombre">Hombre</option>
                                            '<option value="Mujer">Mujer</option>
                                        '</select>
                                    </div>
                                </div>
                            </div>   
                            <div class="col-md-12">
                                <div class="form__element">
                                    <div class="form__title">Antecedentes del equipo que trabaja en la innovación</div>
                                    <div class="form__input">
                                        <input id="leader_record" type="text" class="form-control" name="leader_record" v-model="form.leader_record">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">    

                                <div class="form__element">
                                    <div class="form__title">¿De qué trata tu innovación?</div>
                                    <div class="form__input">
                                        <textarea id="description" name="description" v-model="form.description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>        
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification == true">Complete el Nombre innovación postulada
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <a class="btn" @click.prevent="goToStep(1)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(3)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="3" v-if="currentStep == 3">  
                        <div class="cols">
                            <div class="col-md-12">
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Cuál fue el problema que diagnosticaron?</div>
                                    <div class="form__input">
                                        <textarea id="market_opportunity" name="market_opportunity" v-model="form.market_opportunity"></textarea>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Cómo la innovación resuelve el problema?</div>
                                    <div class="form__input">
                                        <textarea id="solution" name="solution" v-model="form.solution"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿A quiénes impacta esta innovación?</div>
                                    <div class="form__input">
                                        <textarea id="qualitative_impact" name="qualitative_impact" v-model="form.qualitative_impact"></textarea>
                                    </div>
                                </div>
                            </div>



                            
                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Quiénes son sus principales competidores tanto a nivel nacional como internacional? ¿Cuál es el valor agregado en relación a otros emprendimientos de la misma naturaleza?</div>
                                    <div class="form__input">
                                        <textarea id="competitors" name="competitors" v-model="form.competitors"></textarea>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿De qué forma se diferencian?</div>
                                    <div class="form__input">
                                        <textarea id="differences" name="differences" v-model="form.differences"></textarea>
                                    </div>
                                </div>
                            </div>-->
                            <div class="col-md-12">
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿De qué forma la innovación se sustenta y permite proyectar su continuidad, demuestra su potencial escalabilidad y los beneficios que ha generado a la sociedad?</div>
                                    <div class="form__input">
                                        <textarea id="value_creation" name="value_creation" v-model="form.value_creation"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div class="modal__buttons">
                            <a class="btn" @click.prevent="goToStep(2)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(4)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="4" v-if="currentStep == 4">
                        <div class="cols">
                            <div class="col-md-12">    
                                <div class="form__element">
                                    <div class="form__title">¿Existe algún derecho de propiedad intelectual / industrial asociado a la innovación?</div>
                                    <div class="form__input">
                                        <select class="form-control" id="intellectual_property" name="intellectual_property" v-model="form.intellectual_property">
                                            <option value="Si" >Si</option>
                                            <option value="No" >No</option>
                                            <option value="trmt" >Se encuentra en tramitación</option>
                                        '</select>
                                    </div>
                                </div>
                            </div>    
                            <div class="col-md-12">   
                                <div class="form__element">
                                    <div class="form__title">(De ser SI) Indique el nombre de la patente. Otros antecedentes importantes relacionados a derechos y/o licencias de la innovación</div>
                                    <div class="form__input">
                                        <textarea id="patent_name" name="patent_name" v-model="form.patent_name"></textarea>
                                    </div>
                            </div>  
                            <!--<div class="col-md-12">  
                                <div class="form__element">
                                    <div class="form__title">Otros antecedentes importantes relacionados a derechos y/o licencias de la innovación</div>
                                    <div class="form__input">
                                        <textarea id="other_records" name="other_records" v-model="form.other_records"></textarea>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-12">  
                                <div class="form__element">
                                    <div class="form__title">¿Cómo cuantifica usted el impacto de la innovación, la creación de valor en la cantidad de usuarios alcanzados?</div>
                                    <div class="form__input">
                                        <textarea id="quantitative_impact" name="quantitative_impact" v-model="form.quantitative_impact"></textarea>
                                    </div>
                                </div>
                            </div>   
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Ventas 2021 (en pesos chilenos) - Ventas anuales</div>
                                    <div class="form__input">
                                        <select class="form-control" id="quantitative_sales" name="quantitative_sales" v-model="form.quantitative_sales">
                                            <option value="No Registra" >No registra venta</option>
                                            <option value="0-MM$1" >0-MM$1</option>
                                            <option value="MM$1-MM$20" >MM$1-MM$20</option>
                                            <option value="MM$20-MM$100" >MM$20-MM$100</option>
                                            <option value="+MM$100" >+MM$100</option>
                                            <option value="No aplica" >No aplica</option>
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-6">    
                                <div class="form__element">
                                    <div class="form__title">Ventas 2022 (en pesos chilenos) - Ventas anuales</div>
                                    <div class="form__input">
                                        <select class="form-control" id="quantitative_sales_2" name="quantitative_sales_2" v-model="form.quantitative_sales_2">
                                            <option value="No Registra" >No registra venta</option>
                                            <option value="0-MM$1" >0-MM$1</option>
                                            <option value="MM$1-MM$20" >MM$1-MM$20</option>
                                            <option value="MM$20-MM$100" >MM$20-MM$100</option>
                                            <option value="+MM$100" >+MM$100</option>
                                            <option value="No aplica" >No aplica</option>
                                        '</select>
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="modal__buttons">
                            <a class="btn" @click.prevent="goToStep(3)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(5)">Siguiente</a>

                        </div>
                    </div>
                    </div>
                    <div class="modal__sep modal__sep--show" id="5" v-if="currentStep == 5">     
                        <div class="cols">
                            <!-- <div class="col-md-6">
                                <div class="form__element">
                                    <div class="form__title">¿Con cuál o cuáles de los 17 Objetivos de Desarrollo Sostenible planteados por la ONU se relaciona tu innovación? De las metas que propone ese(s) objetivo(s) ¿Cuál soluciona su innovación? ¿Por qué? ¿Cómo?</div>
                                    <div class="form__input">
                                        <textarea id="quali_onu_innovation" name="quali_onu_innovation" v-model="form.quali_onu_innovation"></textarea>
                                    </div>
                                </div>                    
                            </div>       -->

                            <div class="col-md-12">

                                <div class="form__element"> 
                                    <div class="form__title">¿Con cuál o cuáles de los 17 Objetivos de Desarrollo Sostenible planteados por la ONU se relaciona tu innovación?</div>
                                    <div class="form__checkboxs">
                                        <div class="form__checkbox">
                                        <input id="quali_onu" type="hidden" name="quali_onu_innovation">
                                        
                                        <label><input type="checkbox" name="example[]" value="Fin de la Pobreza" checked class="form__checkbox--intellectual">1.- Fin de la Pobreza</label>
                                        <label><input type="checkbox" name="example[]" value="Hambre Cero" class="form__checkbox--intellectual">2.- Hambre Cero</label>
                                        <label><input type="checkbox" name="example[]" value="Salud y Bienestar" class="form__checkbox--intellectual">3.- Salud y Bienestar</label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Educación de Calidad" class="form__checkbox--intellectual">4.- Educación de Calidad
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Igualdad de Género" class="form__checkbox--intellectual">5.- Igualdad de Género
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Agua Limpia y Saneamiento" class="form__checkbox--intellectual">6.- Agua Limpia y Saneamiento
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Energía Asequible y No Contaminante" class="form__checkbox--intellectual">7.- Energía Asequible y No Contaminante
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Trabajo Decente y Crecimiento Económico" class="form__checkbox--intellectual">8.- Trabajo Decente y Crecimiento Económico
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Industria, Innovación e Infraestructura" class="form__checkbox--intellectual">9.- Industria, Innovación e Infraestructura
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Reducción de las Desigualdades" class="form__checkbox--intellectual">10.- Reducción de las Desigualdades
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Ciudades y Comunidades Sostenibles" class="form__checkbox--intellectual">11.- Ciudades y Comunidades Sostenibles
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Producción y Consumo Responsables" class="form__checkbox--intellectual">12.- Producción y Consumo Responsables
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Acción por el Clima" class="form__checkbox--intellectual">13.- Acción por el Clima
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Vida Submarina" class="form__checkbox--intellectual">14.- Vida Submarina
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Vida de Ecosistemas Terrestres" class="form__checkbox--intellectual">15.- Vida de Ecosistemas Terrestres
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Paz, Justicia e Instituciones Sólidas" class="form__checkbox--intellectual">16.- Paz, Justicia e Instituciones Sólidas
                                        </label>
                                        <label>
                                        <input type="checkbox" name="example[]" value="Alianzas para lograr los Objetivos" class="form__checkbox--intellectual">17.- Alianzas para lograr los Objetivos
                                        </label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- <div class="col-md-12">              
                                <div class="form__element">
                                    <div class="form__title">De las metas que propone ese(s) objetivo(s) ¿Cuál soluciona su innovación? ¿Por qué? ¿Cómo?</div>
                                    <div class="form__input">
                                        <textarea id="innovation_impact" name="innovation_impact" v-model="form.innovation_impact"></textarea>
                                    </div>
                                </div>                    
                            </div> -->
                            <!--<div class="col-md-6">                
                                <div class="form__element">
                                    <div class="form__title">¿De qué forma la innovación se puede replicar en otras áreas de empresas o instituciones?</div>
                                    <div class="form__input">
                                        <textarea id="diffusion_factor" name="diffusion_factor" v-model="form.diffusion_factor"></textarea>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-12">              
                                <div class="form__element">
                                    <div class="form__title">¿De qué forma la innovación aporta al desarrollo sostenible del país y/o de la región?</div>
                                    <div class="form__input">
                                        <textarea id="quali_impact_environmental" name="quali_impact_environmental" v-model="form.quali_impact_environmental"></textarea>
                                    </div>
                                </div>                    
                            </div>
                            <!--<div class="col-md-12">                
                                <div class="form__element">
                                    <div class="form__title">¿Cuál es el potencial de difusión en términos comunicacionales de la innovación?</div>
                                    <div class="form__input">
                                        <textarea id="diffusion_potential" name="diffusion_potential" v-model="form.diffusion_potential"></textarea>
                                    </div>
                                </div>  
                            </div> -->

                            <!--<div class="col-md-12">       
                                <div class="form__element">
                                    <div class="form__title">¿La innovación tiene proyección de expansión? (De ser SI) ¿Hacia dónde? ¿De qué forma?</div>
                                    <div class="form__input">
                                        <textarea id="diffusion_projection_desc" name="diffusion_projection_desc" v-model="form.diffusion_projection_desc"></textarea>
                                    </div>
                                </div>
                            </div> -->

                            <!-- 


                            <div class="col-md-6">  
                                <div class="form__element">
                                    <div class="form__title">¿La innovación tiene proyección de expansión?</div>
                                    <div class="form__input">
                                        <select class="form-control" id="diffusion_projection" name="diffusion_projection" v-model="form.diffusion_projection">
                                            <option value="0">Si</option>
                                            <option value="1">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            -->

                            
				              
                            <div class="col-md-12">  
                                <div class="form__element">
                                    <div class="form__title">Adjuntar archivo, solo se aceptan PDF y JPG/PNG</div>
                                    <div class="form__input">
                                        <input id="file_attached" type="file" class="form-control" name="file_attached" @change="processFile($event)">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Link con Material Audiovisual (Opcional)</div>
                                    <div class="form__input">
                                        <input id="diffusion_factor" type="text" class="form-control" name="diffusion_factor" v-model="form.diffusion_factor">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <p>{{successMessagePostulation}}</p>         
                        <div class="modal__buttons">
                            <a class="btn" @click.prevent="goToStep(4)">Atras</a>
                            <button :disabled="savePostulation" class="btn" href="">Guardar</button>
                        </div>             
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'AddPostulations',
    props: ['currentEvaluations','postulationName','idPostulation'],
    mounted() {},
    computed: mapGetters([]),
    data() {
       return {
           currentStep:1,
           savePostulation: false,
           successMessagePostulation: '',
           notification:false,
           form: {
               enterprise:'',
               region:'',
               category:'',
               enterprise_id:'',
               company_id:'',
               region_id:'',
               category_id:'',
               innovation_name:'',
               other_place:'',
               leader:'',
               leader_gender:'',
               leader_job_title:'',
               leader_record:'',
               market_opportunity:'',
               solution:'',
               qualitative_impact:'',
               competitors:'',
               differences:'',
               value_creation:'',
               intellectual_property:'',
               patent_name:'',
               other_records:'',
               quantitative_impact:'',
               quantitative_sales:'',
               quantitative_sales_2:'',
               quali_onu_innovation:'',
               innovation_impact:'',
               diffusion_factor:'',
               diffusion_potential:'',
               diffusion_projection:'',
               diffusion_projection_desc:'',
               file_attached:'',
               quali_impact_environmental:''
           }
       }    
    },
    created() {
        this.categories = this.$store.getters.categoriesList;
        this.regions = this.$store.getters.regionsList;
        this.companies = this.$store.getters.companiesList;
    },
    methods: {
        ...mapActions(["fetchCategories"]),
        closemodal: function(){
            var element2 = document.getElementById("modal-postulation");
            element2.classList.remove("modal--in");
            this.currentStep = 1
        },
        closemodalevaluations: function(){
            var element_evaluations = document.getElementById("modal-evaluations");
            element_evaluations.classList.remove("modal--in");
        },
        goToStep: function(step) {
            if(step == 2){

                if (this.form.company_id == '' || this.form.region_id == '' || this.form.category_id == ''){
                    console.log("complete los campos")
                    this.notification = 1

                }else{
                    this.currentStep = step;
                    this.notification = false;
                }
            }else{
                if(step == 3){
                    if (this.form.innovation_name == ''){
                        this.notification = 1
                    }else{
                        this.currentStep = step;
                        this.notification = false;
                    }
                }else{
                    this.currentStep = step;
                }
            }
                
        },
        newPostulant() {
            this.savePostulation = true;

            var url = process.env.VUE_APP_URL_API+'/postulant/postulation/create';

            var body = new FormData;

            let checkedsExam = [];
            let checks = document.getElementsByName("example[]");

            for (let i=0; i < checks.length; i++) {
                if(checks[i].checked) checkedsExam.push(checks[i].value);
            }

            checkedsExam = checkedsExam.join('-');

            body.append('id_enterprise', this.form.company_id);
            body.append('id_region',this.form.region_id);
            body.append('id_category',this.form.category_id);
            body.append('innovation_name',this.form.innovation_name);
            body.append('other_place',this.form.other_place);
            body.append('leader',this.form.leader);
            body.append('leader_gender',this.form.leader_gender);
            body.append('leader_record',this.form.leader_record);
            body.append('description',this.form.description);
            body.append('market_opportunity',this.form.market_opportunity);
            body.append('solution',this.form.solution);
            body.append('qualitative_impact',this.form.qualitative_impact);
            body.append('competitors',this.form.competitors);
            body.append('value_creation',this.form.value_creation);
            body.append('intellectual_property',this.form.intellectual_property);
            body.append('patent_name',this.form.patent_name);
            body.append('quantitative_impact',this.form.quantitative_impact);
            body.append('quantitative_sales',this.form.quantitative_sales);
            body.append('quantitative_sales_2',this.form.quantitative_sales_2);
            body.append('quali_onu_innovation',checkedsExam);
            body.append('innovation_impact',this.form.innovation_impact);
            body.append('quali_impact_environmental',this.form.quali_impact_environmental);
            body.append('diffusion_potential',this.form.diffusion_potential);
            body.append('diffusion_factor',this.form.diffusion_factor);
            body.append('diffusion_projection_desc',this.form.diffusion_projection_desc);
            body.append('file_attached',this.form.file_attached);

            var params =  {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${body._boundary}`
                },
            }


            axios.post(url,body,params)
                .then((response) => {
                    if(!response.data.success) throw response.data.message;
                    //this.login = response.data;
                   // window.localStorage.setItem('token',  response.data.token_type+' '+response.data.access_token)
                   //window.localStorage.setItem('user',  JSON.stringify(response.data.data.user))
                    this.$router.push('/postulaciones');
                    this.savePostulation = true;
                    this.successMessagePostulation = response.data.message;
                })
                .catch((error) => {
                    this.savePostulation = false;
                    this.error = error;
                });
        },
        processFile(event) {
            console.log("evento input file")
            this.form.file_attached = event.target.files[0]
        }
        
    },
    beforeUnmount() {

    }
}
</script>