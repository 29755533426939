<template>
  <div class="categories">
    <section class="section--box section--box--min">
        <div class="section__header">
          <h4 class="section__title">Usuarios Administradores</h4>
        </div>

        <GetFilterAdministrators />
        <GetAllAdministrators />
    </section>    
      
  </div>
</template>

<script>

  import GetFilterAdministrators from '@/components/administrators/GetFilterAdministrators.vue';
  import GetAllAdministrators from '@/components/administrators/GetAllAdministrators.vue';

  export default {
    name: 'Administrators',
    mounted () {

    },
    components: {
      GetAllAdministrators,
      GetFilterAdministrators
    },
  }
</script>
