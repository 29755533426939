import axios from 'axios'

const state = { 
    regions: []
};

const getters = { 
    regionsList: state => state.regions
};

const actions = { 
    async fetchRegions({commit},num=1){
        var url = process.env.VUE_APP_URL_API+'/auth/regions/all?page='+num;
        var token = window.localStorage.getItem('token');

        var params =  {
            headers: {
                Authorization: token,
            }
        }

        const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );

        commit("setRegions", response.data.data);
    }
};

const mutations = { 
    setRegions: (state, regions) => (
        state.regions = regions
    )
};

export default {
    state,
    getters,
    actions,
    mutations
}