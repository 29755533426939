<template>

    <div>
        <ExportToXls :categoryFilter="categoryFilterXls" :regionFilter="regionFilterXls"/>
        <div class="section__filters">
            <h5 class="section__title"> Filtros </h5>
            <div class="cols">
                <div class="col-md-2">
                    <div class="form__element">
                        <div class="form__input">
                            <select @change="catIdChange($event)" name="category">
                                <option v-for="(category, key) in categoriesList.data"
                                        :value="category.id"
                                        :key="key">
                                {{category.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form__element">
                        <div class="form__input">
                        <select v-model="regId" name="region">
                                <option value="" selected>Seleccione...</option>
                                <option v-for="(region, key) in regionsList.data"
                                        :value="region.id"
                                        :key="key">
                                {{region.name}}
                                </option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
//import axios from 'axios'
//import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";
import ExportToXls from '@/components/evaluations/ExportToXls.vue';

export default {
    name: 'GetFilterEvaluations',
    mounted() {
        //console.log(this.categoriesList.data[0].id);
        this.catId = this.categoriesList.data[0].id;
        //this.regId = this.regionsList.data[0].id;
        this.regId = "";
        this.categoryFilterXls = this.catId;
        this.regionFilterXls = this.regId;

        //console.log(this.catId+' -------  '+this.regId);

        //let params = 'category_id='+this.catId+'&region_id='+this.regId;

        //this.fetchEvaluationsExperts(params);
    },
    computed: mapGetters(['categoriesList','regionsList']),
    data(){
        return {
            catId:"",
            regId:"",
            categoryFilterXls:'',
            regionFilterXls:'',
        }
    },
    components: {
      ExportToXls
    },
    created() {
        this.fetchRegions();
        this.fetchCategories();
        //this.categories = this.$store.getters.categoriesList;
        //this.regions = this.$store.getters.regionsList;
    },
    methods: {
        ...mapActions(["fetchCategories","fetchRegions",'fetchEvaluationsExperts']),
        catIdChange (event) {
            this.catId = event.target.value;
            this.categoryFilterXls = event.target.value;

        },
        regIdChange (event) {
            this.regId = event.target.value;
            this.regionFilterXls = event.target.value;
        },

    },
    beforeUnmount() {

    },
    watch: {
        catId: {
            //handler: function(val, oldVal) {
            handler: function(val) {
              //  console.log(val);
                //this.fetchEvaluations(val)
                //console.log(val)
                let params = 'category_id='+val+'&region_id='+this.regId;

                this.fetchEvaluationsExperts(params);
            },
            deep: true
        },
        regId: {
            //handler: function(val, oldVal) {
            handler: function(val) {
              //  console.log(val);
                //this.fetchEvaluations(val)
                //console.log(val)
                let params = 'category_id='+this.catId+'&region_id='+val;

                this.fetchEvaluationsExperts(params);
            },
            deep: true
        }
    }
}
</script>
