<template>
  <div>
    <div class="modal modal--newpostulation" id="modal-update-postulation">
        <div class="modal__back"></div>
        <div class="modal__front modal__front--scroll">
            <div class="modal__box modal__box--form">
            <div class="modal__loader"></div>
            <div class="modal__close" @click="closemodal()"><span class="fa fa-close"></span></div>
            <div class="modal__content">
                <div class="modal__form">

                <h3 v-if="user.relations.expert != null" class="modal__title">Revisa la postulación</h3>
                <h3 v-else class="modal__title">Revisa el estado de tu postulación</h3>

                <form v-if="postulation != undefined" class="form--validation form--register" v-on:submit.prevent="updatePostulant">

                    <div class="modal__sep modal__sep--show" id="1" v-if="currentStep == 1">
                        <div class="cols">
                            <div class="col-md-4">
                                <div class="form__element">
                                    <div class="form__title">Empresa</div>
                                    <div class="form__input">
                                        <select name="company_id" id="company_id" :disabled="user.relations.expert != null">
                                            <option v-for="(company, key) in this.companies.data" 
                                                    :value="company.id"
                                                    :key="key" :selected="company.id == form.enterprise_id">
                                            {{company.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element">
                                    <div class="form__title">Región en la que opera la empresa</div>
                                    <div class="form__input">
                                        <select name="region_id" id="region_id" :disabled="user.relations.expert != null">
                                            <option v-for="(region, key) in this.regions.data" 
                                                    :value="region.id"
                                                    :key="key" :selected="region.id == form.region_id">
                                            {{region.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"> 
                                <div class="form__element">
                                    <div class="form__title">Categoría</div>
                                    <div class="form__input">
                                        <select v-model="form.category_id" name="category_id" id="category_id" :disabled="user.relations.expert != null">
                                            <option v-for="(category, key) in this.categories.data" 
                                                    :value="category.id"
                                                    :key="key" :selected="category.id == form.category_id">
                                            {{category.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification == true">Complete los campos
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <a class="btn" @click.prevent="goToStep(2)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="2" v-if="currentStep == 2"> 
                        <div class="cols">
                            <div class="col-md-6">
                                <div class="form__element">
                                    <div class="form__title">Nombre innovación postulada</div>
                                    <div class="form__input">
                                        <input :disabled="user.relations.expert != null" id="innovation_name" type="text" class="form-control" name="innovation_name" v-model="form.innovation_name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Región (o país) en la que se implementa la innovación</div>
                                    <div class="form__input">
                                        <input :disabled="user.relations.expert != null" id="other_place" type="text" class="form-control" name="other_place" v-model="form.other_place">
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Nombre y cargo de quien lidera la innovación</div>
                                    <div class="form__input">
                                        <input :disabled="user.relations.expert != null" id="leader" type="text" class="form-control" name="leader" v-model="form.leader">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form__element">
                                    <div class="form__title">Sexo</div>
                                    <div class="form__input">
                                        <select :disabled="user.relations.expert != null" class="form-control" id="leader_gender" name="leader_gender" v-model="form.leader_gender">
                                            <option value="Hombre" :selected="form.leader_gender == 'Hombre'">Hombre</option>
                                            <option value="Mujer" :selected="form.leader_gender == 'Mujer'">Mujer</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-12">
                                <div class="form__element">
                                    <div class="form__title">Antecedentes del equipo que trabaja en la innovación</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="leader_record" name="leader_record" v-model="form.leader_record"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">    

                                <div class="form__element">
                                    <div class="form__title">¿De qué trata tu innovación?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="description" name="description" v-model="form.description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>        
                        <div class="modal__buttons">
                            <div class="modal__notification" v-if="notification == true">Complete el Nombre innovación postulada
                                <span class="fa fa-exclamation"></span>
                            </div>
                            <a class="btn" @click.prevent="goToStep(1)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(3)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="3" v-if="currentStep == 3">  
                        <div class="cols">
                            
                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Cuál fue el problema que diagnosticaron?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="market_opportunity" name="market_opportunity" v-model="form.market_opportunity"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Cómo la innovación resuelve el problema?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="solution" name="solution" v-model="form.solution"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿A quiénes impacta esta innovación?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="qualitative_impact" name="qualitative_impact" v-model="form.qualitative_impact"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">    
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿Quiénes son sus principales competidores tanto a nivel nacional como internacional? ¿Cuál es el valor agregado en relación a otros emprendimientos de la misma naturaleza?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="competitors" name="competitors" v-model="form.competitors"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form__element form__element--textarea">
                                    <div class="form__title">¿De qué forma la innovación se sustenta y permite proyectar su continuidad, demuestra su potencial escalabilidad y los beneficios que ha generado a la sociedad?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="value_creation" name="value_creation" v-model="form.value_creation"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div class="modal__buttons">
                            <a class="btn" @click.prevent="goToStep(2)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(4)">Siguiente</a>
                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="4" v-if="currentStep == 4">
                        <div class="cols">
                            <div class="col-md-12">    
                                <div class="form__element">
                                    <div class="form__title">¿Existe algún derecho de propiedad intelectual / industrial asociado a la innovación?</div>
                                    <div class="form__input">
                                        <select :disabled="user.relations.expert != null" class="form-control" id="intellectual_property" name="intellectual_property" v-model="form.intellectual_property">
                                            <option value="Si" :selected="form.intellectual_property == 'Si'">Si</option>
                                            <option value="No" :selected="form.intellectual_property == 'No'">No</option>
                                            <option value="trmt" :selected="form.intellectual_property == 'trmt'">Se encuentra en tramitación</option>
                                        </select>
                                    </div>
                                </div>
                            </div>    
                            <div class="col-md-12">  
                                <div class="form__element">
                                    <div class="form__title">(De ser SI) Indique el nombre de la patente. Otros antecedentes importantes relacionados a derechos y/o licencias de la innovación</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="patent_name" name="patent_name" v-model="form.patent_name"></textarea>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-12">  
                                <div class="form__element">
                                    <div class="form__title">¿Cómo cuantifica usted el impacto de la innovación, la creación de valor en la cantidad de usuarios alcanzados?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="quantitative_impact" name="quantitative_impact" v-model="form.quantitative_impact"></textarea>
                                    </div>
                                </div>
                            </div>   
                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Ventas 2021 (en pesos chilenos) - Ventas anuales</div>
                                    <div class="form__input">
                                        <select :disabled="user.relations.expert != null" class="form-control" id="quantitative_sales" name="quantitative_sales" v-model="form.quantitative_sales">
                                            <option value="No Registra" :selected="form.quantitative_sales == 'No Registra'">No registra venta</option>
                                            <option value="0-MM$1" :selected="form.quantitative_sales == '0-MM$1'">0-MM$1</option>
                                            <option value="MM$1-MM$20" :selected="form.quantitative_sales == 'MM$1-MM$20'">MM$1-MM$20</option>
                                            <option value="MM$20-MM$100" :selected="form.quantitative_sales == 'MM$20-MM$100'">MM$20-MM$100</option>
                                            <option value="+MM$100" :selected="form.quantitative_sales == '+MM$1000'">+MM$100</option>
                                            <option value="No aplica" :selected="form.quantitative_sales == 'No aplica'">No aplica</option>
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-6">    
                                <div class="form__element">
                                    <div class="form__title">Ventas 2022 (en pesos chilenos) - Ventas anuales</div>
                                    <div class="form__input">
                                        <select :disabled="user.relations.expert != null" class="form-control" id="quantitative_sales_2" name="quantitative_sales_2" v-model="form.quantitative_sales_2">
                                            <option value="No Registra" :selected="form.quantitative_sales_2 == 'No Registra'">No registra venta</option>
                                            <option value="0-MM$1" :selected="form.quantitative_sales_2 == '0-MM$1'">0-MM$1</option>
                                            <option value="MM$1-MM$20" :selected="form.quantitative_sales_2 == 'MM$1-MM$20'">MM$1-MM$20</option>
                                            <option value="MM$20-MM$100" :selected="form.quantitative_sales_2 == 'MM$20-MM$100'">MM$20-MM$100</option>
                                            <option value="+MM$100" :selected="form.quantitative_sales_2 == '+MM$1000'">+MM$100</option>
                                            <option value="No aplica" :selected="form.quantitative_sales_2 == 'No aplica'">No aplica</option>
                                        '</select>
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="modal__buttons">
                            <a class="btn" @click.prevent="goToStep(3)">Atras</a>
                            <a class="btn" @click.prevent="goToStep(5)">Siguiente</a>

                        </div>
                    </div>

                    <div class="modal__sep modal__sep--show" id="5" v-if="currentStep == 5">     
                        <div class="cols">
                            <div class="col-md-12">
                                <!-- 
                                    
                                    <div class="form__element">
                                    <div class="form__title">¿Con cuál o cuáles de los 17 Objetivos de Desarrollo Sostenible planteados por la ONU se relaciona tu innovación?</div>
                                    <div class="form__input">
                                        <textarea id="quali_onu_innovation" name="quali_onu_innovation" v-model="form.quali_onu_innovation"></textarea>
                                    </div>
                                </div>
                                    
                                    --->

                                <div class="form__element"> 
                                <div class="form__title">¿Con cuál o cuáles de los 17 Objetivos de Desarrollo Sostenible planteados por la ONU se relaciona tu innovación?</div>
                                <div class="form__checkboxs">
                                    <div class="form__checkbox">
                                    <input :disabled="user.relations.expert != null" id="quali_onu" type="hidden" name="quali_onu_innovation">
                                    <label v-for="(quali, key) in form.quali_onu_innovation" :key="key">
                                        <input :disabled="user.relations.expert != null" :checked="quali['checked'] == true" :value="quali['name']" type="checkbox" name="example[]">{{quali['name']}}
                                    </label>
                                    </div>
                                </div>
                                </div>

                            </div>

                            <!--


                                <div class="col-md-12">              
                                <div class="form__element">
                                    <div class="form__title">De las metas que propone ese(s) objetivo(s) ¿Cuál soluciona su innovación? ¿Por qué? ¿Cómo?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="innovation_impact" name="innovation_impact" v-model="form.innovation_impact"></textarea>
                                    </div>
                                </div>                    
                            </div>


                            -->

                            <div class="col-md-12">              
                                <div class="form__element">
                                    <div class="form__title">¿De qué forma la innovación aporta al desarrollo sostenible del país y/o de la región?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="quali_impact_environmental" name="quali_impact_environmental" v-model="form.quali_impact_environmental"></textarea>
                                    </div>
                                </div>                    
                            </div>


                            <!--

                                <div class="col-md-12">                
                                <div class="form__element">
                                    <div class="form__title">¿Cuál es el potencial de difusión en términos comunicacionales de la innovación?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="diffusion_potential" name="diffusion_potential" v-model="form.diffusion_potential"></textarea>
                                    </div>
                                </div>  
                            </div>
                            -->

                            <!--


                                <div class="col-md-12">       
                                <div class="form__element">
                                    <div class="form__title">¿La innovación tiene proyección de expansión? (De ser SI) ¿Hacia dónde? ¿De qué forma?</div>
                                    <div class="form__input">
                                        <textarea :disabled="user.relations.expert != null" id="diffusion_projection_desc" name="diffusion_projection_desc" v-model="form.diffusion_projection_desc"></textarea>
                                    </div>
                                </div>
                            </div>

                            -->

                            
				              
                            <div class="col-md-6">  
                                <div class="form__element" v-if="user.relations.administrator != null || user.relations.postulant != null">
                                    <div class="form__title">Adjuntar archivo, solo se aceptan PDF y JPG/PNG </div>
                                    <div class="form__input">
                                        <input  id="file_attached" type="file" accept="application/pdf,.jpg,.png" class="form-control" name="file_attached" @change="processFileUpdate($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">  
                                <div class="form__element">
                                    <div class="form__title">Ver archivo</div>
                                    <div class="form__input">
                                        <a class="attached" v-if="attached != ''" :href="attached" target="_blank">Ver documento</a>
                                        <a class="formFileAttached" v-if="form.file_attached != '' && (typeof form.file_attached === 'string')" :href="form.file_attached" target="_blank">Ver documento</a>
                                        <p v-if="form.file_attached == '' && attached == ''">No hay documento adjunto</p>
                                    </div>
                                </div>
                            </div>  
                        </div>

                            <div class="col-md-6">   
                                <div class="form__element">
                                    <div class="form__title">Link con Material Audiovisual (Opcional)</div>
                                    <div class="form__input">
                                        <input :disabled="user.relations.expert != null" id="diffusion_factor" type="text" class="form-control" name="diffusion_factor" v-model="form.diffusion_factor">
                                    </div>
                                </div>
                            </div>
                        
                        <div class="modal__buttons">
                            <div class="modal__success" v-if="notificationSuccess == true">Has guardado con éxito</div>
                            <a class="btn" @click.prevent="goToStep(4)">Atras</a>
                            <button v-if="user.relations.administrator != null || user.relations.postulant != null" class="btn" href="">Guardar</button>
                        </div>             
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
//import { mapGetters, mapActions } from "vuex";
import { mapActions } from "vuex";

export default {
    name: 'UpdatePostulations',
    props: ['postulation'],
    data(){
        return {
            attached:'',
            currentStep:1,
            notification:false,
            notificationSuccess: false,
            user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null,
            form: {
               enterprise:'',
               region:'',
               category:'',
               enterprise_id:'',
               company_id:'',
               region_id:'',
               category_id:'',
               innovation_name:'',
               other_place:'',
               leader:'',
               leader_gender:'',
               leader_job_title:'',
               leader_record:'',
               market_opportunity:'',
               description:'',
               solution:'',
               qualitative_impact:'',
               competitors:'',
               differences:'',
               value_creation:'',
               intellectual_property:'',
               patent_name:'',
               other_records:'',
               quantitative_impact:'',
               quantitative_sales:'',
               quantitative_sales_2:'',
               quali_onu_innovation:'',
               innovation_impact:'',
               diffusion_factor:'',
               diffusion_potential:'',
               diffusion_projection:'',
               diffusion_projection_desc:'',
               file_attached:'',
               quali_impact_environmental:''
           }
        }
    },
    created() {
        this.categories = this.$store.getters.categoriesList;
        this.regions = this.$store.getters.regionsList;
        this.companies = this.$store.getters.companiesList;
    },
    methods:{
        ...mapActions(["fetchPostulations"]),
        closemodal: function(){
            var element2 = document.getElementById("modal-update-postulation");
            element2.classList.remove("modal--in");
            this.currentStep = 1
        },
        goToStep: function(step) {
            
                if(step == 3){
                    if (this.form.innovation_name == ''){
                        this.notification = 1
                    }else{
                        this.currentStep = step;
                        this.notification = false;
                    }
                }else{
                    this.currentStep = step;
                }

        },
        updatePostulant(){

            let url = process.env.VUE_APP_URL_API+`/auth/postulations/${this.postulation.id}/update`;
            let token = window.localStorage.getItem('token');

           // let body = new FormData;
            var body = new FormData;

            let checkedsExam = [];
            let checks = document.getElementsByName("example[]");

            for (let i=0; i < checks.length; i++) {
                if(checks[i].checked) checkedsExam.push(checks[i].value);
            }

            checkedsExam = checkedsExam.join('-');

            body.append('id_enterprise', this.form.company_id);
            body.append('id_region',this.form.region_id);
            body.append('id_category',this.form.category_id);
            body.append('innovation_name',this.form.innovation_name);
            body.append('other_place',this.form.other_place);
            body.append('leader',this.form.leader);
            body.append('leader_gender',this.form.leader_gender);
            body.append('leader_record',this.form.leader_record);
            body.append('description',this.form.description);
            body.append('market_opportunity',this.form.market_opportunity);
            body.append('solution',this.form.solution);
            body.append('qualitative_impact',this.form.qualitative_impact);
            body.append('competitors',this.form.competitors);
            body.append('value_creation',this.form.value_creation);
            body.append('intellectual_property',this.form.intellectual_property);
            body.append('patent_name',this.form.patent_name);
            body.append('quantitative_impact',this.form.quantitative_impact);
            body.append('quantitative_sales',this.form.quantitative_sales);
            body.append('quantitative_sales_2',this.form.quantitative_sales_2);
            body.append('quali_onu_innovation',checkedsExam);
            body.append('innovation_impact',this.form.innovation_impact);
            body.append('quali_impact_environmental',this.form.quali_impact_environmental);
            body.append('diffusion_potential',this.form.diffusion_potential);
            body.append('diffusion_projection',this.form.diffusion_projection);
            body.append('diffusion_projection_desc',this.form.diffusion_projection_desc);
            body.append('file_attached',this.form.file_attached);
            body.append('diffusion_factor',this.form.diffusion_factor);

            var params =  {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                    'Authorization': token
                },
            }

            console.log('body >>> ',body)

            axios.post(url,body,params)
                .then((response) => {
                    if(!response.data.success) throw response.data.message;
                    //this.login = response.data;
                   // window.localStorage.setItem('token',  response.data.token_type+' '+response.data.access_token)
                   //window.localStorage.setItem('user',  JSON.stringify(response.data.data.user))
                    this.notificationSuccess = true;

                    this.fetchPostulations();
                    this.form.file_attached = '';
                    //this.attached = URL.createObjectURL(response.data.data.file_attached);
                    this.attached = response.data.data.file_attached;

                    console.log(this.attached);
                     
                    console.log(response.data.data.file_attached);
                })
                .catch((error) => {
                    this.error = error;
                    console.log(error);
                });
        },
        processFileUpdate(event) {
            console.log("evento input file")
            this.form.file_attached = event.target.files[0];
            console.log(event.target.files[0])
        }
    },
    watch: {
        postulation: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                console.log(val);

                this.form.enterprise = this.postulation == undefined ? '' : this.postulation.id ;
                this.form.region = this.postulation == undefined ? '' : this.postulation.id ;
                this.form.category = this.postulation == undefined ? '' : this.postulation.id ;
                this.form.enterprise_id = this.postulation == undefined ? '' : this.postulation.enterprise_id ;
                this.form.company_id = this.postulation == undefined ? '' : this.postulation.enterprise_id ;
                this.form.region_id = this.postulation == undefined ? '' : this.postulation.region_id ;
                this.form.category_id = this.postulation == undefined ? '' : this.postulation.category_id ;
                this.form.innovation_name = this.postulation == undefined ? '' : this.postulation.innovation_name ;
                this.form.other_place = this.postulation == undefined ? '' : this.postulation.other_place ;
                this.form.leader = this.postulation == undefined ? '' : this.postulation.leader ;
                this.form.leader_gender = this.postulation == undefined ? '' : this.postulation.leader_gender ;
                this.form.leader_job_title = this.postulation == undefined ? '' : this.postulation.leader_job_title ;
                this.form.leader_record = this.postulation == undefined ? '' : this.postulation.leader_record ;
                this.form.market_opportunity = this.postulation == undefined ? '' : this.postulation.market_opportunity ;
                this.form.solution = this.postulation == undefined ? '' : this.postulation.solution ;
                this.form.competitors = this.postulation == undefined ? '' : this.postulation.competitors ;
                this.form.differences = this.postulation == undefined ? '' : this.postulation.differences ;
                this.form.value_creation = this.postulation == undefined ? '' : this.postulation.value_creation ;
                this.form.intellectual_property = this.postulation == undefined ? '' : this.postulation.intellectual_property ;
                this.form.patent_name = this.postulation == undefined ? '' : this.postulation.patent_name ;
                this.form.other_records = this.postulation == undefined ? '' : this.postulation.other_records ;
                
                this.form.qualitative_impact = this.postulation == undefined ? '' : this.postulation.qualitative_impact ;

                this.form.quantitative_impact = this.postulation == undefined ? '' : this.postulation.quantitative_impact ;
                this.form.quantitative_sales = this.postulation == undefined ? '' : this.postulation.quantitative_sales ;
                this.form.quantitative_sales_2 = this.postulation == undefined ? '' : this.postulation.quantitative_sales_2 ;
                this.form.quali_onu_innovation = this.postulation == undefined ? '' : this.postulation.quali_onu_innovation ;
                this.form.innovation_impact = this.postulation == undefined ? '' : this.postulation.innovation_impact ;
                this.form.diffusion_factor = this.postulation == undefined ? '' : this.postulation.diffusion_factor ;
                this.form.diffusion_potential = this.postulation == undefined ? '' : this.postulation.diffusion_potential ;
                this.form.diffusion_projection = this.postulation == undefined ? '' : this.postulation.diffusion_projection ;
                this.form.diffusion_projection_desc = this.postulation == undefined ? '' : this.postulation.diffusion_projection_desc ;
                this.form.file_attached = this.postulation == undefined ? '' : this.postulation.file_attached;
                this.form.description = this.postulation == undefined ? '' : this.postulation.description;
                this.form.quali_impact_environmental = this.postulation == undefined ? '' : this.postulation.quali_impact_environmental;
            },
            deep: true
        },
    }
}
</script>

<style>

</style>
