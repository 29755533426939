<template>
        <div class="section__table" id="section__table--postulations">

            <div class="section__table__row">

                <div class="section__table__element">
                    <div class="section__table__title"></div>
                </div>

                <div class="section__table__element" v-for="(expert) in evalsExpertsList" :key="expert">
                    <div class="section__table__title">{{expert}}</div>
                </div>

                <div class="section__table__element">
                    <div class="section__table__title">Prom. Final</div>
                </div>

            </div>

            <div class="section__table__row" v-for="(postulation) in evalsList" :key="postulation.innovation_name">

                <div class="section__table__element">
                    <div class="section__table__title">Nombre innovación</div>
                    <div class="section__table__value" v-if="postulation.innovation_name<35"> {{postulation.innovation_name}}</div>
                    <div class="section__table__value" v-else> {{postulation.innovation_name.substring(0,35)+".."}}</div>
                </div>

                <div class="section__table__element" v-for="(evaluation) in postulation.evaluations" :key="evaluation.name_expert">
                    <div class="section__table__value">{{evaluation.final_note}}</div>
                </div>

                <div class="section__table__element">
                    <div class="section__table__title">{{postulation.prom_final}}</div>
                </div>

            </div>
            <pagination v-model="page" :records="1" :per-page="20"></pagination>
        </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'GetAllEvaluations',
    created(){
        //this.fetchEvaluations();
        //this.fetchEvaluationsExperts();
    },
    mounted() {
    },
    data() {
       return {
           page: 1
       }    
    },
    computed: mapGetters(["evalsList","evalsExpertsList"]),
    methods: {
       // ...mapActions(["fetchEvaluationsExperts"])
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                //this.fetchEvaluations(val)
                console.log(val)
            },
            deep: true
        }
    }
}
</script>