<template>
        <div class="section__filters">
            <div class="cols">
              <div class="col-md-12">
                  <div class="form__element">
                      <a class="btn" @click="btnNew()">Agregar Nueva</a>
                  </div>
              </div>
            </div>
        </div>
 
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";


export default {
    name: 'GetAllFinancials',
    mounted() {},
    computed: mapGetters(["financialsList"]),
    created() {},
    methods: {
        ...mapActions(["fetchFinancials"]),
        btnNew:async function(){
            await Swal.fire({
            title: 'Nuevo sector Económico',
            html:
            '<div class="form-group">'+
                '<br><div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre del sector económico</div>'+
                        '<div class="form__input">'+
                            '<input id="new_name"" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            preConfirm: () => {
                if(document.getElementById('new_name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'name'  : document.getElementById('new_name').value
                    }
                    var url = process.env.VUE_APP_URL_API+'/auth/economicsectors/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    //forma correcta de enviar url, body y params en axios
                    axios.post(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                this.fetchFinancials()
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                            }else{
                                console.log(response.data.message) //mensaje de error}
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                        }

                },
            })
            
        },   
    },
    beforeUnmount() {

    }
}
</script>