<template>
  <div class="regions">

    <section class="section--box section--box--min">
        <div class="section__header">
          <h4 class="section__title">Regiones</h4>
        </div>
        <GetFilterRegions />
        <GetAllRegions />
    </section>
  </div>
</template>

<script>

  import GetFilterRegions from '@/components/regions/GetFilterRegions.vue';
  import GetAllRegions from '@/components/regions/GetAllRegions.vue';

  export default {
    name: 'Regions',
    mounted () {

    },
    components: {
      GetAllRegions,
      GetFilterRegions,
    },
  }
</script>
