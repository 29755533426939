<template>
    
    <div class="section__table" id="section__table--postulations">
        <div class="section__table__row" v-for="(expert) in expertsList.data" :key="expert.id">
            <div class="section__table__element section__table__element--id">
                <div class="section__table__title">ID</div>
                <div class="section__table__value">{{expert.id}}</div>
            </div>
            <div class="section__table__element">
                <div class="section__table__title">Nombre</div>
                <div class="section__table__value">{{expert.name}}</div>
            </div>
            <div class="section__table__element">
                <div class="section__table__title">Email</div>
                <div class="section__table__value">{{expert.user.email}}</div>
            </div>
            <div class="section__table__element">
                <div class="section__table__title">Categoría</div>
                <div class="section__table__value">{{expert.category.name}}</div>
            </div>
            <div class="section__table__element">
                <div class="section__table__title">Sector</div>
                <div class="section__table__value">{{expert.regional.name}}</div>
            </div>
            <div class="section__table__actions">
            <div class="section__table__title">Acciones</div>
            <div class="section__table__icons">
                <div class="section__table__icon"   @click="btnUpdate(expert)" ><span class="fa fa-edit"></span></div>
                <div class="section__table__icon"   @click="Delete(expert)"><span class="fa fa-trash"></span></div>
                
            </div>
            </div>
        </div>
        <pagination v-model="page" :records="expertsList.total ?? 1" :per-page="20" ></pagination>
    </div>

</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllExperts',
    data: function() {
        return {
            sectors: [],
            categories: [],
            page: 1
        };
    },
    computed: mapGetters(["expertsList"]),
    created() {
      this.fetchExperts();
      this.fetchCategories();
      this.fetchSectors();
    },
    mounted() {
        this.categories = this.$store.getters.categoriesList;
        this.sectors = this.$store.getters.sectorsList;
    },
    methods: {
        ...mapActions(["fetchExperts",'fetchCategories','fetchSectors']),
        btnUpdate:async function(expert){
            var sectors ='', sector ='', categories ='', category ='';

            this.sectors.data.forEach( function(value) {
                if(value.id == expert.regional_id)
                    sector = "<option value="+value.id+" selected>"+value.name+"</option>"
                else
                    sector = "<option value="+value.id+">"+value.name+"</option>"

                sectors = sectors + sector
            });

            this.categories.data.forEach( function(value) {
                if(value.id == expert.category_id)
                    category = "<option value="+value.id+" selected>"+value.name+"</option>"
                else
                    category = "<option value="+value.id+">"+value.name+"</option>"

                categories = categories + category
            });
            await Swal.fire({
                title: 'Editar Experto',
                html:
                    '<div class="form-group">'+
                        '<br><div class="row">'+
                            '<div class="form__element">'+
                                '<div class="form__title">Nombre del experto</div>'+
                                '<div class="form__input">'+
                                    '<input id="name" type="text" class="form-control" value="'+expert.name+'">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Correo</div>'+
                                '<div class="form__input">'+
                                    '<input id="email" type="email" class="form-control" value="'+expert.user.email+'">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Contraseña</div>'+
                                '<div class="form__input">'+
                                    '<input id="password" type="password" class="form-control">'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Sector</div>'+
                                '<div class="form__input">'+
                                    '<select class="form-control" id="id_regional"> '+sectors+'</select>'+
                                '</div>'+
                            '</div>'+
                            '<div class="form__element">'+
                                '<div class="form__title">Categoría</div>'+
                                '<div class="form__input">'+
                                    '<select class="form-control" id="id_category"> '+categories+'</select>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'name'  : document.getElementById('name').value,
                            'id'  : expert.id,
                            'email' : document.getElementById('email').value,
                            'password' : document.getElementById('password').value,
                            'id_regional' : document.getElementById('id_regional').value,
                            'id_category' : document.getElementById('id_category').value,
                            'active' : true

                        }
                        var url = process.env.VUE_APP_URL_API+'/auth/experts/'+expert.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                if (response.data.code==200) {
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchExperts();
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                console.log("else lol")
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            })
            
        },   

        Delete:async function(expert){
            await Swal.fire({
            title: 'Borrar Experto',
            html: '¿Seguro que deseas borrar este experto <strong>'+expert.name+' ('+expert.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Experto Eliminado', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/experts/'+expert.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchExperts();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        }    
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchExperts(val)
            },
            deep: true
        }
    }
}
</script>
