<template>
  <div class="logout">
    <img alt="Vue logo" src="../assets/logo.png">
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Logout',
  mounted() {
   window.localStorage.removeItem('token');
   window.localStorage.removeItem('user');
   //this.$router.push('/');
   window.location.href = "/";
  },
  beforeUnmount() {
    console.log('vista login desmontada');
  }
}
</script>
