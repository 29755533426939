<template>
    
    <div>
        <ExportAllToXls/>
        <div v-if="user.relations.administrator != null || user.relations.postulant != null" class="section__add"><a class="btn" @click="btnNew()">Agregar Nueva</a></div>
        <div class="section__filters">
            <h5 class="section__title"> Filtros </h5>
            <div class="cols">
                <div class="col-md-3">
                    <div class="form__element">
                        <div class="form__input">
                        <input v-on:keyup="rutFilter($event)" type="text" placeholder="Rut Postulante"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form__element">
                        <div class="form__input">
                        <input v-on:keyup="nameFilter($event)" type="text" placeholder="Nombre"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form__element">
                        <div class="form__input">
                            <select @change="categoryFilter($event)" name="category">
                                <option value="">Seleccione...</option>
                                <option v-for="(category, key) in this.categories.data" 
                                        :value="category.id"
                                        :key="key">
                                {{category.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form__element">
                        <div class="form__input">
                        <select @change="regionFilter($event)" name="region">
                                <option value="">Seleccione...</option>
                                <option v-for="(region, key) in this.regions.data" 
                                        :value="region.id"
                                        :key="key">
                                {{region.name}}
                                </option>
                        </select>
                        </div>
                    </div>
                </div>
                <!--<div class="col-md-2">
                    <div class="form__element">
                        <div class="form__input" placeholder="Fecha desde">
                        <input @change="dateFromFilter($event)" type="date" />
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form__element">
                        <div class="form__input">
                        <input @change="dateToFilter($event)" type="date" placeholder="Fecha hasta"/>
                            
                        </div>
                    </div>
                </div> --->
            </div>
        </div>
    </div>


</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";
import ExportAllToXls from '@/components/postulations/ExportAllToXls.vue';

export default {
    name: 'GetFilterPostulations',
    mounted() {},
    computed: mapGetters([]),
    data(){
        return {
            user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null,
        }
    },
    components: {
        ExportAllToXls
    },
    created() {
        this.categories = this.$store.getters.categoriesList;
        this.regions = this.$store.getters.regionsList;
    },
    methods: {
        ...mapActions(["fetchPostulations"]),
        btnNew:async function(){

            var element2 = document.getElementById("modal-postulation");
            
            element2.classList.add("modal--in");

            if(document.getElementById('name').value == ""){
                Swal.showValidationMessage(
                    `Error: "Completa el campo"`
                )
            }else{
                Swal.showValidationMessage()
                var element = document.getElementById("swal2-validation-message");
                element.classList.add("swal2-success-message-hide");
                var body = {
                    'name'  : document.getElementById('name').value
                }
                var url = process.env.VUE_APP_URL_API+'/auth/postulations/create';
                var token = window.localStorage.getItem('token');
                var params =  {
                    headers: {
                        Authorization: token,
                    },
                }
                //forma correcta de enviar url, body y params en axios
                axios.post(url,body,params)
                    .then((response) => {
                        if (response.data.code==200) {
                            
                            Swal.showValidationMessage(
                                `Success: ${response.data.message}`
                            )
                            element.classList.add("swal2-success-message");
                            this.fetchCategories();
                        }else{
                            console.log(response.data.message) //mensaje de error}
                            Swal.showValidationMessage(
                                `Success: ${response.data.message}`
                            )
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                            }
                        });
                    }
            
        }, 
        btnFilters: function(){
            this.fetchPostulations(['category_id',event.target.value]);
        },

        categoryFilter (event) {
            this.$emit('category-filter', event.target.value);

        },
        regionFilter (event) {
            this.$emit('region-filter', event.target.value);

        },
        dateFromFilter (event) {
            this.$emit('date-from-filter', event.target.value);

        },
        dateToFilter (event) {
            this.$emit('date-to-filter', event.target.value);

        },
        nameFilter (event) {
            this.$emit('name-filter', event.target.value);

        },
        rutFilter (event) {
            this.$emit('rut-filter', event.target.value);

        },

        closemodal: function(){
            var element2 = document.getElementById("modal-postulation");
            element2.classList.remove("modal--in");
        },


        
        nextmodal: function(number){
            console.log(number)
        }


    },
    beforeUnmount() {

    }
}
</script>