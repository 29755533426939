<template>
  <header>
    <div class="header__primary">
      <div class="header__wrap">
        <div class="header__inner">
          <div class="header__nav">
            <div class="header__nav__bg"></div>
            <div class="header__nav__nav">
              <div class="header__nav__close">
                <div></div>
                <div></div>
              </div>
              <div class="header__nav__back">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="header__right">
            <div class="header__item header__item--login" v-if="user">
                <div class="header__item__text">{{ user.email}}</div>
                <router-link class="btn btn--white btn--xs" to="/logout">Cerrar sesión</router-link>
            </div> 
            <div class="header__item header__item--ham">
              <div class="header__ham"><span></span><span></span><span></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <aside v-if="user">
    <div class="aside__logo"><a href="/"><img src="icons/avonnipremio.png" /></a></div>
    <nav id="nav" v-if="user">
      <ul>
        <li class="current current_menu_item"><router-link to="/">Dashboard<span class="fa fa-pie-chart"></span></router-link></li>
        <li v-if="user.relations.administrator != null"></li>
        <li v-if="user.relations.administrator != null" class="aside__sep">Usuarios<span class="fa fa-users"></span>
          <ul class="sub__menu">
            <li><router-link to="/user-administrators">Administradores</router-link></li>
            <li><router-link to="/user-postulants">Postulantes</router-link></li>
            <li><router-link to="/user-experts">Expertos</router-link></li>
          </ul>
        </li>
        <li v-if="user.relations.administrator != null || user.relations.postulant != null"><router-link to="/empresas">Empresas<span class="fa fa-building-o"></span></router-link></li>
        <li><router-link to="/postulaciones">Postulaciones<span class="fa fa-files-o"></span></router-link></li>
        <li v-if="user.relations.administrator != null"><router-link to="/evaluaciones">Evaluaciones<span class="fa fa-clipboard"></span></router-link></li>
        <li v-if="user.relations.administrator != null"><router-link to="/categorias">Categorias<span class="fa fa-cubes"></span></router-link></li>
        <li v-if="user.relations.administrator != null"><router-link to="/sectores">Sectores<span class="fa fa-map-pin"></span></router-link></li>
        <li v-if="user.relations.administrator != null"><router-link to="/regiones">Regiones<span class="fa fa-map-marker"></span></router-link></li>
        <li v-if="user.relations.administrator != null"><router-link to="/sectores_economicos">Sectores Económicos<span class="fa fa-puzzle-piece"></span></router-link></li>

      </ul>
    </nav>
  </aside>  
  <router-view/>
</template>
<script>

  export default {
    data() {
       return {
           user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null
       }    
    },
  }
</script>

<style lang="stylus">
@import 'assets/stylus/imports.styl'
@import url('https://fonts.gstatic.com')
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap')
</style>