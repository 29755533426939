<template>
        <div class="section__filters">
          <div class="cols">
              <div class="col-md-4">
                  <div class="form__element">
                      <div class="form__title">Buscar</div>
                      <div class="form__input">
                      <input type="text" />
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="form__element">
                      <div class="form__title">A-Z</div>
                      <div class="form__input">
                      <select>
                          <option selected="selected" value="*">Todos</option>
                      </select>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="form__element">
                      <a class="btn" @click="btnNew()">Agregar Nuevo</a>
                  </div>
              </div>
          </div>
        </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetFilterPostulants.vue',
    mounted() {},
    computed: mapGetters(["postulantsList"]),
    created() {},
    methods: {
        ...mapActions(["fetchPostulants"]),
        btnNew:async function(){
            await Swal.fire({
            title: 'Nuevo Postulante',
            html:
            '<div class="form-group">'+
                '<div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre</div>'+
                        '<div class="form__input">'+
                            '<input id="first_name" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Apellido paterno</div>'+
                        '<div class="form__input">'+
                            '<input id="father_last_name" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Apellido materno</div>'+
                        '<div class="form__input">'+
                            '<input id="mother_last_name" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Correo</div>'+
                        '<div class="form__input">'+
                            '<input id="email" type="email" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Rut</div>'+
                        '<div class="form__input">'+
                            '<input id="rut" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Género</div>'+
                        '<div class="form__input">'+
                            '<input type="radio" id="gender" name="gender" value="female"> Femenino'+
                            '<input type="radio" id="male" name="gender" value="male"> Masculino'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Teléfono</div>'+
                        '<div class="form__input">'+
                            '<input id="phone" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Fecha de Nacimiento</div>'+
                        '<div class="form__input">'+
                            '<input id="birth_day" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Empresa donde trabajas</div>'+
                        '<div class="form__input">'+
                            '<input id="enterprise" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Cargo</div>'+
                        '<div class="form__input">'+
                            '<input id="job_title" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                '</div>'+
            '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Crear',
            preConfirm: () => {
                if(document.getElementById('first_name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'first_name'  : document.getElementById('first_name').value,
                        'father_last_name'  : document.getElementById('father_last_name').value,
                        'mother_last_name'  : document.getElementById('mother_last_name').value,
                        'email'  : document.getElementById('email').value,
                        'rut'  : document.getElementById('rut').value,
                        'gender'  : 'male',
                        'phone'  : document.getElementById('phone').value,
                        'birth_day'  : document.getElementById('birth_day').value,
                        'enterprise'  : document.getElementById('enterprise').value,
                        'job_title'  : document.getElementById('job_title').value,
                    }
                    var url = process.env.VUE_APP_URL_API+'/postulant/create';
                    var token = window.localStorage.getItem('token');
                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    //forma correcta de enviar url, body y params en axios
                    axios.post(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                                this.fetchPostulants  ();
                            }else{
                                console.log(response.data.message) //mensaje de error}
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                        }

                },
            })
            
        },    
    },
    beforeUnmount() {

    }
}
</script>