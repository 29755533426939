import { createRouter, createWebHistory } from 'vue-router'

import auth from '../middleware/auth';
import log from '../middleware/log';
import isAdmin from '../middleware/isAdmin';
//import isExpert from '../middleware/isExpert';

import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Administrators from '../views/Administrators.vue'
import Postulants from '../views/Postulants.vue'
import Companies from '../views/Companies.vue'
import Categories from '../views/Categories.vue'
import Postulations from '../views/Postulations.vue'
import Experts from '../views/Experts.vue'
import Sectors from '../views/Sectors.vue'
import Regions from '../views/Regions.vue'
import Financials from '../views/Financials.vue'
import Logout from '../views/Logout.vue'
import Evaluations from '../views/Evaluations.vue'


export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: log,
    },
  },
  {
    path: '/user-administrators',
    name: 'Administrators',
    component: Administrators,
    meta: {
      middleware: [log,isAdmin],
    },
  },
  {
    path: '/user-postulants',
    name: 'Postulants',
    component: Postulants,
    meta: {
      middleware: [log,isAdmin],
    },
  },
  {
    path: '/empresas',
    name: 'Companies',
    component: Companies,
    meta: {
      middleware: log,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      middleware: log,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: '/categorias',
    name: 'Categories',
    component: Categories,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
  {
    path: '/postulaciones',
    name: 'Postulations',
    component: Postulations,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: '/user-experts',
    name: 'Experts',
    component: Experts,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
  {
    path: '/sectores',
    name: 'Sectors',
    component: Sectors,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
  {
    path: '/regiones',
    name: 'Regions',
    component: Regions,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
  {
    path: '/sectores_economicos',
    name: 'Financials',
    component: Financials,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
  {
    path: '/evaluaciones',
    name: 'Evaluations',
    component: Evaluations,
    meta: {
      middleware: [auth, log, isAdmin],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});


export default router
