<template>
        <div class="section__table" id="section__table--financials">
            <div class="section__table__row" v-for="(financial) in financialsList.data" :key="financial.id">
                <div class="section__table__element section__table__element--id">
                    <div class="section__table__title">ID</div>
                    <div class="section__table__value">{{financial.id}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Nombre</div>
                    <div class="section__table__value">{{financial.name}}</div>
                </div>

                <div class="section__table__actions">
                <div class="section__table__title">Acciones</div>
                  <div class="section__table__icons">
                      <div class="section__table__icon" @click="btnUpdate(financial)" ><span class="fa fa-edit"></span></div>
                      <div class="section__table__icon" @click="btnDelete(financial)"><span class="fa fa-trash"></span></div>
                  </div>
                </div>
            </div>
            <pagination v-model="page" :records="financialsList.total ?? 1" :per-page="20" ></pagination>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";


export default {
    name: 'GetAllFinancials',
    mounted() {},
    computed: mapGetters(["financialsList"]),
    created() {
      this.fetchFinancials()
    },
    data() {
       return {
           page: 1
       }    
    },
    methods: {
        ...mapActions(["fetchFinancials"]),
        btnUpdate:async function(sector){ 
            await Swal.fire({
                title: 'Editar Sector económico',
                html:
                '<div class="form-group">'+
                    '<br><div class="row">'+
                        '<div class="form__element">'+
                            '<div class="form__title">Nombre del sector</div>'+
                            '<div class="form__input">'+
                                '<input id="name" value="'+sector.name+'" type="text" class="form-control">'+
                            '</div>'+
                        '</div>'+
                    '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'name'  : document.getElementById('name').value
                        }
                        var url = process.env.VUE_APP_URL_API+'/auth/economicsectors/'+sector.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                if (response.data.code==200) {
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchFinancials()
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                console.log("else lol")
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            })
            
        },
        btnDelete:async function(sector){
            await Swal.fire({
            title: 'Borrar Categoría',
            html: '¿Seguro que deseas borrar el sector económico <strong>'+sector.name+' ('+sector.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    var url = process.env.VUE_APP_URL_API+'/auth/economicsectors/'+sector.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then(() => {
                        this.fetchFinancials()
                        Swal.fire('Sector económico Eliminado', '', 'success')
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        },
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchFinancials(val)
            },
            deep: true
        }
    }
}
</script>