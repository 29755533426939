import axios from 'axios'

const state = { 
    categories: []
};

const getters = { 
    categoriesList: state => state.categories
};

const actions = { 
    async fetchCategories({commit},num=1){
      var url = process.env.VUE_APP_URL_API+'/auth/categories/all?page='+num;
      var token = window.localStorage.getItem('token');

      var params =  {
          headers: {
              Authorization: token,
          }
      }

      const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );
      commit("setCategories", response.data.data);

    },
};

const mutations = { 
    setCategories: (state, categories) => (
        state.categories = categories
    ),
};

export default {
    state,
    getters,
    actions,
    mutations
}