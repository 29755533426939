<template>
  <div class="sectors">
    <section class="section--box section--box--min">
        <div class="section__header">
            <h4 class="section__title">Sectores Regionales</h4>
        </div>
        <GetFilterSectors />
        <GetAllSectors />
    </section>
  </div>
</template>

<script>

  import GetFilterSectors from '@/components/sectors/GetFilterSectors.vue';
  import GetAllSectors from '@/components/sectors/GetAllSectors.vue';

  export default {
    name: 'Sectors',
    mounted () {

    },
    components: {
      GetAllSectors,
      GetFilterSectors,
    },
  }
</script>
