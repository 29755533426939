<template>
  <div class="experts">
    <section class="section--box">
        <div class="section__header">
            <h4 class="section__title">Expertos</h4>
        </div>
        <GetFilterExperts />
        <GetAllExperts />
      </section>
  </div>
</template>

<script>

  import GetFilterExperts from '@/components/experts/GetFilterExperts.vue';
  import GetAllExperts from '@/components/experts/GetAllExperts.vue';

  export default {
    name: 'Experts',
    mounted () {

    },
    components: {
      GetAllExperts,
      GetFilterExperts
    },
  }
</script>
