<template>
        <div class="section__table" id="section__table--regions">
            <div class="section__table__row" v-for="(region) in regionsList.data" :key="region.id">
                <div class="section__table__element section__table__element--id">
                    <div class="section__table__title">ID</div>
                    <div class="section__table__value">{{region.id}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Nombre</div>
                    <div class="section__table__value">{{region.name}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Sector</div>
                    <div class="section__table__value">{{region.regional.name}}</div>
                </div>

            </div>
            <pagination v-model="page" :records="regionsList.total ?? 1" :per-page="20"></pagination>
        </div>

</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllRegions',
    mounted() {
        this.sectors = this.$store.getters.sectorsList;
    },
    computed: mapGetters(["sectorsList","regionsList"]),
    created(){
        this.fetchRegions();
    },
    data() {
       return {
           page: 1
       }    
    },
    methods: {
        ...mapActions(["fetchRegions"]),
        btnUpdate:async function(id, name,id_sector){
            var sectors = this.sectors
            var options = ""
            var html
            sectors.forEach( function(value) {
                if(value.id == id_sector)
                    html = "<option value="+value.id+" selected>"+value.name+"</option>"
                else
                    html = "<option value="+value.id+">"+value.name+"</option>"

                options = options + html
            });          
            await Swal.fire({
            title: 'Editar Región' + name,
            html:
            '<div class="form-group">'+
                '<br><div class="row">'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre de la región</div>'+
                        '<div class="form__input">'+
                            '<input id="name" value="'+name+'" type="text" class="form-control">'+
                        '</div>'+
                    '</div>'+
                    '<div class="form__element">'+
                        '<div class="form__title">Nombre del Sector</div>'+
                        '<div class="form__select">'+
                            '<select id="sector" class="form-control">'+options+'</div>'+
                        '</div>'+
                    '</div>'+
                '</div>', 
            focusConfirm: false,
            showCancelButton: true, 
            confirmButtonText: 'Actualizar',
            preConfirm: () => {
                if(document.getElementById('name').value == ""){
                    Swal.showValidationMessage(
                        `Error: "Completa el campo"`
                    )
                }else{
                    
                    Swal.showValidationMessage()
                    var element = document.getElementById("swal2-validation-message");
                    element.classList.add("swal2-success-message-hide");
                    var body = {
                        'id' : id,
                        'name' : document.getElementById('name').value,
                        'regional_id' : document.getElementById('sector').value
                    }
                    var url = process.env.VUE_APP_URL_API+'/auth/regions/'+body.id+'/update';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    //forma correcta de enviar url, body y params en axios
                    axios.put(url,body,params)
                        .then((response) => {
                            if (response.data.code==200) {
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                                element.classList.add("swal2-success-message");
                                this.fetchRegions();
                            }else{
                                console.log(response.data.message) //mensaje de error
                                Swal.showValidationMessage(
                                    `Success: ${response.data.message}`
                                )
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                console.log('error 401, no te encuentras autorizado.')
                                this.$router.push('/logout')
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                            }
                        });
                    }

                },
            })
            
        },   
        
        deleteRegion(id){
            var url = process.env.VUE_APP_URL_API+'/auth/regions/'+id+'/delete';
            var token = window.localStorage.getItem('token');

            var params =  {
                headers: {
                    Authorization: token,
                },
            }

            //forma correcta de enviar url, body y params en axios
            axios
            .delete(url,params)
            .then((response) => {
                console.log(response.data.data);
                this.fetchRegions();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    console.log('error 401, no te encuentras autorizado.')
                    //this.$router.push('/logout')
                }
                if (error.response.status === 404) {
                    console.log('error 404, Endpoint no encontrado.')
                }
            });
         },
  
        btnDelete:async function(id,name){
            await Swal.fire({
            title: 'Borrar Región',
            html: '¿Seguro que deseas borrar la región <strong>'+name+' ('+id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    this.deleteRegion(id);
                }
            });
            
        }   
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchRegions(val)
            },
            deep: true
        }
    }
}

</script>