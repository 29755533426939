<template>
  <div class="financials">
    <section class="section--box section--box--min">
        <div class="section__header">
            <h4 class="section__title">Sector Económico</h4>
        </div>
        <GetFilterFinancials />
        <GetAllFinancials />
    </section>
  </div>
</template>

<script>

  import GetFilterFinancials from '@/components/financials/GetFilterFinancials.vue';
  import GetAllFinancials from '@/components/financials/GetAllFinancials.vue';

  export default {
    name: 'Financials',
    mounted () {

    },
    components: {
      GetFilterFinancials,
      GetAllFinancials,
    },
  }
</script>
