export default function isAdmin({ next, router }) {

    if (!localStorage.getItem('token') && !window.localStorage.getItem('user')) {
        return router.push({ name: 'Home' });
    }

    let user = JSON.parse(window.localStorage.getItem('user'));

    if(user.relations.administrator === null){
        return router.push({ name: 'Home' });
    }

    return next();
}