<template>
        <div class="section__table" id="section__table--postulations">
            <div class="section__table__row" v-for="(sector) in sectorsList.data" :key="sector.id">
                <div class="section__table__element section__table__element--id">
                    <div class="section__table__title">ID</div>
                    <div class="section__table__value">{{sector.id}}</div>
                </div>
                <div class="section__table__element">
                    <div class="section__table__title">Nombre</div>
                    <div class="section__table__value">{{sector.name}}</div>
                </div>

                <div class="section__table__actions">
                    <div class="section__table__title">Acciones</div>
                    <div class="section__table__icons">
                        <div class="section__table__icon" @click="Update(sector)" ><span class="fa fa-edit"></span></div>
                        <div class="section__table__icon" @click="Delete(sector)"><span class="fa fa-trash"></span>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-model="page" :records="sectorsList.total ?? 1" :per-page="20"></pagination>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllSectors',
    mounted() {
      this.fetchSectors()
      console.log(this.fetchSectors())
    },
    data() {
       return {
           page: 1
       }    
    },
    computed: mapGetters(["sectorsList"]),
    methods: {
        ...mapActions(["fetchSectors"]),
       Delete:async function(sector){
            await Swal.fire({
            title: 'Borrar Sector Geográfico',
            html: '¿Seguro que deseas borrar el Sector <strong>'+sector.name+' ('+sector.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Sector Eliminado', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/regionals/'+sector.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchSectors();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
         },
        Update:async function(sector){
            await Swal.fire({
                title: 'Editar Sector',
                html:
                '<div class="form-group">'+
                    '<div class="row">'+
                        '<div class="form__element">'+
                            '<div class="form__title">Nombre del sector</div>'+
                                '<div class="form__input">'+
                                    '<input id="name" type="text" class="form-control" value="'+sector.name+'">'+
                                '</div>'+
                            '<div class="form__notification">Sector actualizado correctamente<span class="fa fa-check"></span></div>'+
                        '</div>'+
                    '</div>'+
                '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
            }).then((result) => {
                if (result.value) {
                    var url = process.env.VUE_APP_URL_API+'/auth/regionals/'+sector.id+'/update';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }
                    var body =  {
                        name: document.getElementById('name').value
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .put(url,body,params)
                    .then(() => {
                        this.fetchSectors();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
        },   


    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchSectors(val)
            },
            deep: true
        }
    }
}
</script>