<template>

    <div>


        <div style="margin-right:300px;" class="section__add"><a target="_blank" class="btn" v-if="user.relations.administrator != null || user.relations.expert != null" href="https://drive.google.com/file/d/1HMAOR4SQ7lhJ2bFyB8QVKKdcCRinwf_s/view">Rúbrica</a> </div>
        <div v-if="user.relations.administrator != null" style="margin-right:150px;" class="section__add"><a class="btn" @click="allExportToXls()">Descargar Excel</a></div>

    </div>


</template>

<script>
import axios from 'axios'
/*
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex"; */

export default {
    name: 'ExportAllToXls',
    props: [],
    mounted() {},
    computed: {},
    data(){
        return {
            user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null,
        }
    },
    components: {

    },
    created() {
    },
    methods: {
        allExportToXls(){
            let a = document.createElement("a");
            //RENDERIZAR XLS MEDIANTE AXIOS
            document.body.appendChild(a);
            a.style = "display: none";

            //alert('export to xls');

            let url = process.env.VUE_APP_URL_API+'/auth/postulations/all/current/xls';
            let token = window.localStorage.getItem('token');

            let params =  {
                headers: {
                    Authorization: token,
                },
                responseType: 'blob'
            }

            axios.get(url,params)
                 .then((response) => {
                       // if(!response.data.success) throw response.data.message;

                        var file = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});

                        var fileURL = URL.createObjectURL(file);

                        // Open new windows and show XLS
                        //window.open(fileURL);
                        //this.urlExportPdf = fileURL;

                        a.href = fileURL;
                        a.download = 'postulations_all.xlsx';
                        a.click();
                 })
                 .catch(
                        error => {
                            if (error.response.status === 401) {
                                window.localStorage.removeItem('token');
                                window.localStorage.removeItem('user');
                                window.location.href = "/";
                            }
                            if (error.response.status === 404) {
                                console.log('error 404, Endpoint no encontrado.')
                            }
                        }
                 );
        }

    },
    beforeUnmount() {

    },
}
</script>
