<template>
  <div class="postulations">
    <section class="section--box">
        <div class="section__header">
            <h4 class="section__title">Postulaciones</h4>
        </div>

        <GetFilterPostulations @category-filter="childCategoryValue" @region-filter="childRegionValue" @name-filter="childNameValue" @rut-filter="childRutValue"
        @date-from-filter="childDateFromValue" @date-to-filter="childDateToValue" />
        <GetAllPostulations :postulations="postulationsList.data"/>

        <pagination v-model="page" :records="postulationsList.total ?? 1" :per-page="20"></pagination>
    </section>
  </div>
</template>

<script>

  import GetFilterPostulations from '@/components/postulations/GetFilterPostulations.vue';
  import GetAllPostulations from '@/components/postulations/GetAllPostulations.vue';
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'Postulations',
    created() {
      this.fetchPostulations();
    },
    mounted () {
    },
    methods: {
      ...mapActions(["fetchPostulations"]),
      childCategoryValue: function(params) {
        this.categoryFilter = params;
      },
      childRegionValue: function(params) {
        this.regionFilter = params;
      },
      childNameValue: function(params) {
        this.nameFilter = params;
      },
      childRutValue: function(params) {
        this.rutFilter = params;
      },
      childDateFromValue: function(params) {
        this.dateFromFilter = params;
      },
      childDateToValue: function(params) {
        this.dateToFilter = params;
      },
      filtersAction (page) {
        let parameter = `page=${page}&category_id=${this.categoryFilter}&region_id=${this.regionFilter}&date_from=${this.dateFromFilter}&date_to=${this.dateToFilter}&rut=${this.rutFilter}&name=${this.nameFilter}`;

        this.fetchPostulations([parameter]);
      }
    },
    computed: mapGetters(["postulationsList"]),
    data() {
       return {
           page: 1,
           categoryFilter: "",
           regionFilter:"",
           dateFromFilter:"",
           dateToFilter:"",
           rutFilter:"",
           nameFilter:""
       }    
    },
    components: {
      GetFilterPostulations,
      GetAllPostulations,
    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                console.log(val);
               // this.fetchPostulations(['?page='+val+'&category_id='+this.categoryFilter]);
               this.filtersAction(val);
            },
            deep: true
        },
        categoryFilter: {
          handler: function(val) {
               console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        },
        regionFilter: {
          handler: function(val) {
               console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        },
        nameFilter: {
          handler: function(val) {
            console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        },
        rutFilter: {
          handler: function(val) {
            console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        },
        dateFromFilter: {
          handler: function(val) {
            console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        },
        dateToFilter: {
          handler: function(val) {
            console.log(val);
//                this.page = 1;
                //this.fetchPostulations(['?page=1&category_id='+val]);
                this.filtersAction(1);
            },
          deep: true
        }
    }
  }
</script>
