import axios from 'axios'

const state = { 
    postulations: []
};

const getters = { 
    postulationsList: state => {
        return state.postulations
    }
};

const actions = { 
    async fetchPostulations({commit},args){
        if (typeof args == 'undefined') {
            args = '?page=1'
        }

        //console.log(args);

        var url = process.env.VUE_APP_URL_API+'/auth/postulations/all?'+args;
        var token = window.localStorage.getItem('token');

        var params =  {
            headers: {
                Authorization: token,
            }
        }

        
        const response = await axios.get(url,params)
                            .catch(
                                error => {
                                    if (error.response.status === 401) {
                                        window.localStorage.removeItem('token');
                                        window.localStorage.removeItem('user');
                                        window.location.href = "/";
                                    }
                                    if (error.response.status === 404) {
                                        console.log('error 404, Endpoint no encontrado.')
                                    }
                                }
                            );
        commit("setPostulations", response.data.data);
    }
};

const mutations = { 
    setPostulations(state, payload) {
        state.postulations = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}