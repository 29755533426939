<template>
  <section class="section--graphics" v-if="user.relations.administrator != null">
    <div class="cols" >

      <div class="col-md-4">
        <div  class="section__graphic">
          <h4>Postulaciones por categoria</h4>
          <div ref="chartdiv5" style="width: 100%; height: 300px;"></div> 
        </div>
      </div>
      <div class="col-md-4">
        <div  class="section__graphic">
          <h4>Postulaciones por región</h4>
          <div id="chartdiv2" style="width: 100%; height: 300px;"></div> 
        </div>
      </div>
      <div class="col-md-4">
        <div  class="section__graphic">
          <h4>Postulaciones por género</h4>
          <div ref="chartdiv3" style="width: 100%; height: 300px;"></div> 
        </div>
      </div>
      <div class="col-md-6">
        <div  class="section__graphic">
          <h4>Nombre del gráfico 1</h4>
          <div class="hello" ref="chartdiv" style="width: 100%; height: 200px;"></div>
        </div>
      </div>

      <div class="col-md-6">
        <div  class="section__graphic">
          <h4>Nombre del gráfico 4</h4>
          <div ref="chartdiv4" style="width: 100%; height: 300px;"></div> 
        </div>
      </div>

    </div>
  </section>

  <div v-else style="margin-top: 200px;">
    
    <center>
      <h4 v-if="user.relations.expert != null">Bienvenido(a) experto(a)</h4>
      <h4 v-if="user.relations.expert != null">En la siguiente plataforma podrás evaluar los proyectos que corresponden a tu  categoría, utilizando la rúbrica que está en la pestaña a la derecha.</h4>
      <h4 v-if="user.relations.expert != null">Las notas van desde 1 a 5 y sólo se permiten números enteros. Tienes hasta el 10 de agosto para evaluar. </h4>
      <h3 v-else >Hola, has ingresado como postulante.</h3>
    </center>
    
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
import { mapActions } from "vuex";
am4core.useTheme(am4themes_animated);


export default {
  name: 'HelloWorld',
  data() {
       return {
           user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null
       }    
  },
  mounted() {

    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    chart.paddingRight = 20;

    let data = [];
    let visits = 10;
    for (let i = 1; i < 366; i++) {
      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
    }

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";

    series.tooltipText = "{valueY.value}";
    chart.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    this.chart = chart;
    this.grafico1()
    this.grafico2()
    this.grafico3()
    this.grafico4()
    this.fetchCompanies();
    this.fetchRegions();
    this.fetchCategories();
  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.chart2) {
      this.chart2.dispose();
    }
    if (this.chart3) {
      this.chart3.dispose();
    }
    if (this.chart4) {
      this.chart4.dispose();
    }
  },
  methods: {
    ...mapActions(["fetchCompanies","fetchRegions","fetchCategories"]),
    grafico2(){
      let chart2 = am4core.create("chartdiv2", am4charts.PieChart);
      
      let url = process.env.VUE_APP_URL_API+'/auth/reports/postulations-by-region';
      let token = window.localStorage.getItem('token');

      let params =  {
          headers: {
              Authorization: token,
          }
      }

      axios.get(url,params)
        .then(function (response) {
          // Add data
          chart2.data = response.data.data;

        })
        .catch(
            error => {
                if (error.response.status === 401) {
                  window.localStorage.removeItem('token');
                  window.localStorage.removeItem('user');
                  window.location.href = "/";
                }
                if (error.response.status === 404) {
                    console.log('error 404, Endpoint no encontrado.')
                }
            }
        );

      

      // Add and configure Series
      var pieSeries = chart2.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "total_postulations";
      pieSeries.dataFields.category = "region";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 1;
      pieSeries.slices.template.strokeOpacity = 1

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart2.innerRadius = am4core.percent(20);

    },
    grafico1(){
      am4core.useTheme(am4themes_animated);

      let chart3 = am4core.create(this.$refs.chartdiv3, am4charts.SlicedChart);
      chart3.paddingBottom = 30;

      let url = process.env.VUE_APP_URL_API+'/auth/reports/postulations-by-gender';
      let token = window.localStorage.getItem('token');

      let params =  {
          headers: {
              Authorization: token,
          }
      }

      axios.get(url,params)
        .then(function (response) {
          // Add data
          chart3.data = response.data.data;

        })
        .catch(
            error => {
                if (error.response.status === 401) {
                  window.localStorage.removeItem('token');
                  window.localStorage.removeItem('user');
                    window.location.href = "/";
                }
                if (error.response.status === 404) {
                    console.log('error 404, Endpoint no encontrado.')
                }
            }
        );

      let series = chart3.series.push(new am4charts.PyramidSeries());
      series.dataFields.value = "total_postulations";
      series.dataFields.category = "gender";
      series.alignLabels = true;
      series.valueIs = "height";
    },
    grafico3(){
      // Create chart instance
      let chart4 = am4core.create(this.$refs.chartdiv4, am4charts.XYChart);
      // Add data
      chart4.data = [{
        "country": "Lithuania",
        "litres": 501.9,
        "units": 250
      }, {
        "country": "Czech Republic",
        "litres": 301.9,
        "units": 222
      }, {
        "country": "Ireland",
        "litres": 201.1,
        "units": 170
      }, {
        "country": "Germany",
        "litres": 165.8,
        "units": 122
      }, {
        "country": "Australia",
        "litres": 139.9,
        "units": 99
      }, {
        "country": "Austria",
        "litres": 128.3,
        "units": 85
      }, {
        "country": "UK",
        "litres": 99,
        "units": 93
      }, {
        "country": "Belgium",
        "litres": 60,
        "units": 50
      }, {
        "country": "The Netherlands",
        "litres": 50,
        "units": 42
      }];

      // Create axes
      let categoryAxis = chart4.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "country";
      categoryAxis.title.text = "Countries";

      let valueAxis = chart4.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Litres sold (M)";

      // Create series
      var series = chart4.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "litres";
      series.dataFields.categoryX = "country";
      series.name = "Sales";
      series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
      series.columns.template.fill = am4core.color("#104547");

      var series2 = chart4.series.push(new am4charts.LineSeries());
      series2.name = "Units";
      series2.stroke = am4core.color("#CDA2AB");
      series2.strokeWidth = 3;
      series2.dataFields.valueY = "units";
      series2.dataFields.categoryX = "country";
    },
     grafico4(){
      let chart5 = am4core.create(this.$refs.chartdiv5, am4charts.PieChart);

      let url = process.env.VUE_APP_URL_API+'/auth/reports/postulations-by-category';
      let token = window.localStorage.getItem('token');

      let params =  {
          headers: {
              Authorization: token,
          }
      }

      axios.get(url,params)
        .then(function (response) {
          // Add data
         chart5.data = response.data.data;
          console.log(response.data.data)
        })
        .catch(
            error => {
                if (error.response.status === 401) {
                  window.localStorage.removeItem('token');
                  window.localStorage.removeItem('user');
                  window.location.href = "/";
                }
                if (error.response.status === 404) {
                    console.log('error 404, Endpoint no encontrado.')
                }
            }
        );


        // Add and configure Series
        var pieSeries = chart5.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "total_postulations";
        pieSeries.dataFields.category = "category";
        //pieSeries.slices.template.propertyFields.fill = "color";
        // Disabling labels and ticks on inner circle
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        // Put a thick white border around each Slice
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;

        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart5.innerRadius = am4core.percent(20);

       // chart5.legend = new am4charts.Legend();
     }

  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 500px;
}
</style>
