<template>
        <div class="section__table" id="section__table--categories">

            <div class="section__table__row" v-for="(category) in categoriesList.data" :key="category.id">
                <div class="section__table__element section__table__element--id">
                <div class="section__table__title">ID</div>
                <div class="section__table__value">{{category.id}}</div>
                </div>
                <div class="section__table__element">
                <div class="section__table__title">Nombre</div>
                <div class="section__table__value">{{category.name}}</div>
                </div>
                <div class="section__table__actions">
                <div class="section__table__title">Acciones</div>
                <div class="section__table__icons">
                    <div class="section__table__icon" @click="btnUpdate(category)" ><span class="fa fa-edit"></span></div>
                    <div class="section__table__icon" v-if="category.can_delete"  @click="btnDelete(category)"><span class="fa fa-trash"></span></div>
                </div>
                </div>
            </div>

        <pagination v-model="page" :records="categoriesList.total ?? 1" :per-page="20"></pagination>
        </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'GetAllCategories',
    mounted() {},
    computed: mapGetters(["categoriesList"]),
    created() {
      this.fetchCategories()
    },
    data() {
       return {
           page: 1
       }    
    },
    methods: {
        ...mapActions(["fetchCategories"]),
        btnUpdate:async function(category){ 
            await Swal.fire({
                title: 'Editar Categoría',
                html:
                '<div class="form-group">'+
                    '<br><div class="row">'+
                        '<div class="form__element">'+
                            '<div class="form__title">Nombre de la categoría</div>'+
                            '<div class="form__input">'+
                                '<input id="name" value="'+category.name+'" type="text" class="form-control">'+
                            '</div>'+
                            '<div class="form__notification">Sector actualizado correctamente<span class="fa fa-check"></span></div>'+
                        '</div>'+
                    '</div>', 
                focusConfirm: false,
                showCancelButton: true, 
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    if(document.getElementById('name').value == ""){
                        Swal.showValidationMessage(
                            `Error: "Completa el campo"`
                        )
                    }else{
                        
                        Swal.showValidationMessage()
                        var element = document.getElementById("swal2-validation-message");
                        element.classList.add("swal2-success-message-hide");
                        var body = {
                            'name'  : document.getElementById('name').value,
                            'id'  : category.id,
                        }
                        var url = process.env.VUE_APP_URL_API+'/auth/categories/'+body.id+'/update';
                        var token = window.localStorage.getItem('token');

                        var params =  {
                            headers: {
                                Authorization: token,
                            },
                        }
                        //forma correcta de enviar url, body y params en axios
                        
                        axios.put(url,body,params)
                            .then((response) => {
                                console.log(response.data.data)
                                if (response.data.code==200) {
                                    
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                    element.classList.add("swal2-success-message");
                                    this.fetchCategories();
                                }else{
                                    console.log(response.data.message) //mensaje de error
                                    Swal.showValidationMessage(
                                        `Success: ${response.data.message}`
                                    )
                                }
                            })
                            .catch((error) => {
                                if (error.response.status === 401) {
                                    console.log('error 401, no te encuentras autorizado.')
                                    this.$router.push('/logout')
                                }
                                if (error.response.status === 404) {
                                    console.log('error 404, Endpoint no encontrado.')
                                }
                            });
                    }

                },
            })
            
        },   

        btnDelete:async function(category){
            await Swal.fire({
            title: 'Borrar Categoría',
            html: '¿Seguro que deseas borrar la categoría <strong>'+category.name+' ('+category.id+')</strong>?', 
            focusConfirm: true,
            showCancelButton: true, 
            confirmButtonText: 'Borrar',
            }).then((result) => {
                if (result.value) {
                    Swal.fire('Categoria Eliminada', '', 'success')
                    var url = process.env.VUE_APP_URL_API+'/auth/categories/'+category.id+'/delete';
                    var token = window.localStorage.getItem('token');

                    var params =  {
                        headers: {
                            Authorization: token,
                        },
                    }

                    //forma correcta de enviar url, body y params en axios
                    axios
                    .delete(url,params)
                    .then((response) => {
                        console.log(response.data.data);
                        this.fetchCategories();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            console.log('error 401, no te encuentras autorizado.')
                            //this.$router.push('/logout')
                        }
                        if (error.response.status === 404) {
                            console.log('error 404, Endpoint no encontrado.')
                        }
                    });
                }
            });
            
        } 
    },
    beforeUnmount() {

    },
    watch: {
        page: {
            //handler: function(val, oldVal) {
            handler: function(val) {
                this.fetchCategories(val)
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
